<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <titlebar :title-stack="titleStack"> </titlebar>
        <div class="tags-do-lote">
            <b-tag-list>
                <b-tag v-if="loteGlosa"
                    type="is-danger"
                    aria-close-label="Close tag"
                >
                    {{ $t('CONTASRECEBERCADASTRO.LOTEGLOSA').toUpperCase() }}
                </b-tag>
            </b-tag-list>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box" v-if="notaFiscalConsulta">
                            <p>{{ notaFiscal.mensagem }}</p>
                            <div class="columns">
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.DOCUMENTO')}}</p>
                                        <b>{{ notaFiscal.idIntegracao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.PRESTADOR')}}</p>
                                        <b>{{ notaFiscal.prestador }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.TOMADOR')}}</p>
                                        <b>{{ notaFiscal.tomador }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.VALOR')}}</p>
                                        <b>{{ notaFiscal.valorServico | currency }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.SITUACAO')}}</p>
                                        <b>{{ notaFiscal.situacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.IDENTIFICACAONOTAFISCAL')}}</p>
                                        <b>{{ notaFiscal.id }}</b>
                                    </div>
                                </div>
                            </div>
                            <div v-if="notaFiscal.situacao === 'CONCLUIDO'" class="columns">
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.NUMERONFSE')}}</p>
                                        <b>{{ notaFiscal.numeroNfse }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.SERIE')}}</p>
                                        <b>{{ notaFiscal.serie }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.LOTE')}}</p>
                                        <b>{{ notaFiscal.lote }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.NUMERO')}}</p>
                                        <b>{{ notaFiscal.numero }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.CODIGOVERIFICACAO')}}</p>
                                        <b>{{ notaFiscal.codigoVerificacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.AUTORIZACAO')}}</p>
                                        <b>{{ notaFiscal.autorizacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">{{$t('CONTASRECEBERCADASTRO.MENSAGEM')}}</p>
                                        <b>{{ notaFiscal.mensagem }}</b>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">                                    
                                    <search-id-name :id.sync="model.categoriaFinanceira.id" :label="$t('CONTASRECEBERCADASTRO.CATEGORIAFINANCEIRA')" table="CategoriasFinanceirasReceitas"></search-id-name>
                                </div>

                                <div class="column">
                                    <b-field :label="$t('CONTASRECEBERCADASTRO.DATA')">
                                        <b-datetimepicker v-model="model.data"
                                            v-if="model.id > 0"
                                            :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERDATA')"
                                            icon="calendar-today"
                                            :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                            :disabled="model.id > 0"
                                            horizontal-time-picker
                                            expanded>
                                        </b-datetimepicker>
                                        <b-datepicker v-if="model.id === 0"
                                            v-model="model.data"
                                            :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERDATA')"
                                            icon="calendar-today"
                                            horizontal-time-picker
                                            expanded>
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <searchIdName v-if="model.convenio && !isLoading"
                                        :id.sync="model.convenio.id"
                                        table="convenio"
                                        :label="$t('SISTEMA.CONVENIO')"
                                        :leitura="model.id > 0" />
                                </div>                                
                                <div class="column is-2-desktop is-one-quarter-mobile">
                                    <b-field :label="$t('CONTASRECEBERCADASTRO.NOTAFISCAL')">
                                        <b-input type="text"
                                            v-if="temNotaFiscal && model.id && model.notaFiscal"
                                            v-model="model.notaFiscal"
                                            :has-counter="false"
                                            readonly></b-input>
                                        <b-input type="text"
                                            v-else
                                            v-model="model.notaFiscal"
                                            :has-counter="false"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="column">
                                <searchIdName v-if="model.paciente && !isLoading"                                    
                                    :id.sync="model.paciente.id"
                                    table="paciente"
                                    :label="$t('SISTEMA.PACIENTE')"
                                    :leitura="model.id > 0"
                                    @item="pacienteAlterado" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.funcionario && !isLoading"
                                    :id.sync="model.funcionario.id"
                                    table="funcionario"
                                    :label="$t('CONTASRECEBERCADASTRO.FUNCIONARIO')"
                                    :leitura="model.funcionario.id" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.localDeOrigem && !isLoading"
                                    :id.sync="model.localDeOrigem.id"
                                    table="local"
                                    :label="$t('CONTASRECEBERCADASTRO.LOCALORIGEM')"
                                    :leitura="model.id > 0" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.unidade && !isLoading" 
                                    :id.sync="model.unidade.id"
                                    table="Unidade"
                                    :label="$t('CONTASRECEBERCADASTRO.UNIDADE')"
                                    :leitura="model.id > 0"/>
                            </div>
                            <div class="column" >
                                <b-field :label="$t('CONTASRECEBERCADASTRO.OBSERVACOES')">
                                <textarea class="textarea" v-model="model.observacoes" rows="4"></textarea>
                                </b-field>
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent" v-if="model.quantidadeExames > 0 || model.consumiveis != null && model.consumiveis.length > 0">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'Receber'" :chave="model.id" v-if="model.id"></historico-alteracoes>

                            <article class="panel" v-if="(model.quantidadeExames > 0 && model.convenio.tipoConvenio && model.convenio.tipoConvenio.faturaAberta) || model.consumiveis != null && model.consumiveis.length > 0">
                                <div class="panel-block">
                                    <b-button v-if="model.situacaoReceber == 1"
                                            @click.prevent.stop="fecharFatura()"
                                            class="button is-warning is-fullwidth"
                                            icon-left="lock-open-variant-outline">{{$t('CONTASRECEBERCADASTRO.FECHARFATURA')}}</b-button>
                                    <b-button v-else
                                            class="button is-success is-fullwidth"
                                            icon-left="lock-open-check">{{$t('CONTASRECEBERCADASTRO.FATURAFECHADA')}}</b-button>
                                </div>
                            </article>
                            <div class="panel-heading has-text-centered"
                                 v-if="model.convenio && model.convenio.tissVersao && model.id && model.situacaoReceber == 2 && model.quantidadeExames > 0">
                                {{$t('CONTASRECEBERCADASTRO.TISS')}}
                            </div>
                            <div class="panel-block" v-if="model.convenio && model.convenio.tissVersao && model.id && model.situacaoReceber == 2 && model.quantidadeExames > 0">
                                <router-link :to="{ name: 'xmltiss', params: { receberId: model.id, versao: model.convenio.tissVersao, temConvenioPadrao: model.temConvenioPadrao}}" class="button is-primary-fullwidth">
                                    {{$t('CONTASRECEBERCADASTRO.TISSLOTEGUIAS')}}
                                </router-link>
                            </div>
                            <br v-if="model.id" />
                            <div class="panel-heading has-text-centered"
                                 v-if="model.id && model.situacaoReceber == 2 && (model.quantidadeExames > 0  || model.consumiveis != null && model.consumiveis.length > 0)">
                                {{$t('CONTASRECEBERCADASTRO.GERARDOCUMENTO')}}
                            </div>
                            <div class="panel-block"
                                 v-if="model.id && model.situacaoReceber == 2 && (model.quantidadeExames > 0 || model.consumiveis != null && model.consumiveis.length > 0)">
                                <b-button icon-left="eye"
                                          class="btn-action is-success center"
                                          @click.prevent.stop="download('ContasReceberPDF', 'pdf')"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.PDF')}}</b-button>
                                <b-button icon-left="eye" v-if="model.quantidadeExames > 0"
                                          class="btn-action is-primary center"
                                          @click.prevent.stop="download('ContasReceberPDFSintetico', 'pdf')"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.PDFSINTETICO')}}</b-button>
                                <b-button icon-left="eye"
                                          class="btn-action is-danger center"
                                          @click.prevent.stop="download('ContasReceberXLS', 'xls')"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.XLS')}}</b-button>
                            </div>
                            <br v-if="model.id" />
                            <div class="panel-block" v-if="model.situacaoReceber == 2">
                                <b-button class="btn-action is-primary center"
                                          v-if="!temNotaFiscal && model.id"
                                          @click.prevent.stop="emitirNotaFiscal()"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.EMITIRNOTAFISCAL')}}</b-button>
                                <b-button class="btn-action is-info center"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="consultarNotaFiscal()"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.CONSULTARNOTAFISCAL')}}</b-button>
                            </div>
                            <div class="panel-block"
                                 v-if="temNotaFiscal && model.id && model.situacaoReceber == 2">
                                <b-button class="btn-action is-warning center"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="pdfNotaFiscal()"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.PDFNOTAFISCAL')}}</b-button>
                            </div>
                            <div class="panel-block"
                                 v-if="temNotaFiscal && model.id && model.situacaoReceber == 2">
                                <b-button class="btn-action is-danger center"
                                          icon-left="close-circle"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="cancelarNotaFiscal()"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.CANCELARNOTAFISCAL')}}</b-button>
                            </div>
                            <div class="panel-block">
                                <b-button class="btn-action is-danger center"
                                    icon-left="close-circle"
                                    v-if="!model.cancelada && model.id && model.situacaoReceber == 2"
                                    @click.prevent.stop="modalCancelarFatura()"
                                    expanded>{{$t('CONTASRECEBERCADASTRO.CANCELARFATURA')}}</b-button>
                                <label class="label button is-danger label-parcela-cancelada"
                                    v-if="model.cancelada && model.id && model.situacaoReceber == 2">
                                    {{$t('CONTASRECEBERCADASTRO.PARCELACANCELADA')}}
                                </label>
                                <b-button class="btn-action is-light center"
                                    icon-left="cash-multiple"
                                    v-if="exibirParcelarValor() && model.situacaoReceber == 2"
                                    @click.prevent.stop="dividirParcela()"
                                    expanded>{{$t('CONTASRECEBERCADASTRO.PARCELARVALOR')}}
                                </b-button>
                            </div>
                            <div class="panel-block">
                                <b-button v-if="(descontoConvenioGuia == 0 || descontoConvenioGuia == null || descontoConvenioGuia == undefined) && (desconto == 0 || desconto == null || desconto == undefined)" 
                                          class="btn-action is-light center"
                                          icon-left="cash"
                                          @click.prevent.stop="aplicarDesconto()"
                                          expanded>{{$t('CONTASRECEBERCADASTRO.DESCONTO')}}</b-button>
                                <div v-if="descontoTotal > 0" class="well well-sm">
                                    <b>{{$t('CONTASRECEBERCADASTRO.DESCONTOTOTAL', {'0': formatPrice(descontoTotal)})}}</b>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         v-if="model.convenio && model.convenio.tissVersao"
                         @click="isOpenParcelas = !isOpenParcelas; $forceUpdate();"> {{$t('CONTASRECEBERCADASTRO.PARCELAS')}}
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenParcelas"
                                expanded>
                        <div class="tile is-child notification is-danger"
                             v-if="valorNaoBate">
                            <span>
                                {{ $t('CONTASRECEBERCADASTRO.MENSAGEMVALORNAOBATE',
                                 {'0': somaParcelas(), '1': valorTotalParcelas}) }}
                            </span>
                        </div>
                        <br />
                        <b-table :data="model.parcelas"
                                 striped
                                 hoverable
                                 class="fullwidth"
                                 expanded>
                            <template>
                                <b-table-column v-slot="props" field="" label="Id" >
                                    <span class="tag is-light label">{{ labelId(props.row.id) }}</span>
                                </b-table-column>
                                <b-table-column v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.VENCIMENTO')">
                                    <div class="columns"
                                         v-if=" (props.row.situacao === 1 || props.row.situacao === 5 || props.row.situacao === 6 || props.row.situacao === 7) && isInRole('receber-parcela-alterar-vencimento')">
                                        <div class="column">
                                            <b-datepicker editable
                                                          v-model="props.row.dataDeVencimento"
                                                          readonly
                                                          icon="calendar-today"
                                                          :input="dataDeVencimentoAlterada(props.row)"
                                                          horizontal-date-picker
                                                          expanded>
                                            </b-datepicker>
                                        </div>
                                        <div v-if="props.row.vencimentoAlterado && props.row.id !== 0"
                                             class="column is-6">
                                            <b-select required
                                                      :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERSELMOTIVO')"
                                                      class="is-fullwidth"
                                                      v-model="props.row.motivoVencimentoAlterado">
                                                <option v-for="item in motivosVencimentoAlterado"
                                                        :key="item.id"
                                                        :value="item.id">
                                                    {{ item.descricaoInterno }}
                                                </option>
                                            </b-select>
                                        </div>
                                    </div>
                                    <label class="label"
                                        v-if=" (props.row.situacao !== 1 && props.row.situacao !== 5 && props.row.situacao !== 6 && props.row.situacao !== 7) || !isInRole('receber-parcela-alterar-vencimento')">
                                        {{ props.row.dataDeVencimento | data }}
                                    </label>
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.VALOR')">
                                    <div class="columns"
                                         v-if="props.row.situacao === 1 && isInRole('receber-parcela-alterar-valor')">
                                        <div class="column">
                                            <b-field>
                                                <p class="control">
                                                    <button class="button is-light" type="button">
                                                        R$
                                                    </button>
                                                </p>
                                                <input class="input"
                                                    type="number"
                                                    v-if="qntdeParcelas > 1 || model.quantidadeExames == 0"
                                                    @change="validarValorTotalDasParcelas()"
                                                    step=".00000001"
                                                    v-model="props.row.valor" 
                                                />
                                                 <input class="input"
                                                    v-else
                                                    type="number"
                                                    disabled
                                                    @change="validarValorTotalDasParcelas()"
                                                    step=".00000001"
                                                    v-model="props.row.valor" 
                                                />
                                            </b-field>
                                        </div>
                                        <div v-if="props.row.descontoAplicado" class="column is-6">
                                            <b-select required
                                                      :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERSELMOTIVO')"
                                                      class="is-fullwidth"
                                                      v-model="props.row.motivoDescontoParcela">
                                                <option v-for="item in motivosDescontoParcela"
                                                        :key="item.id"
                                                        :value="item.id">
                                                    {{ item.descricaoInterno }}
                                                </option>
                                            </b-select>
                                        </div>
                                    </div>
                                    <label class="label"
                                           v-if="(props.row.situacao !== 1 && props.row.situacao !== 3) || !isInRole('receber-parcela-alterar-valor')">{{ props.row.valor | currency }}
                                    </label>
                                </b-table-column>

                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.ESPECIE')">
                                    <div>
                                        <div v-if="props.row.situacao === 2">
                                            <span class="help">
                                                {{$t('CONTASRECEBERCADASTRO.ESPECIE')}}
                                                <b>
                                                    {{props.row.especieDeRecebimento.nome || especieRecebimento}}
                                                </b>
                                            </span>
                                        </div>
                                        <div v-else>
                                            <b-field label="">
                                                <b-select v-model="props.row.especieDeRecebimentoSelecionada"
                                                          @input="especieSelecionada(props.row)">
                                                    <option v-for="option in especies"
                                                            :value="option.id"
                                                            :key="option.id">
                                                        {{ option.nome }}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                    </div>
                                </b-table-column>
                                

                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.DADOS')">
                                    <div v-if="props.row.situacao !== 2">
                                        <ValidationProvider :name="$t('CONTASRECEBERCADASTRO.CONTACORRENTEDESTINO')" rules="required" v-slot="{ errors, valid }">
                                            <b-field :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                                    <b-select :placeholder="$t('CONTASRECEBERCADASTRO.CONTACORRENTEDESTINO')"
                                                            v-model="props.row.contaCorrenteSelecionada"
                                                            @input="contaCorrenteSelecionada(props)"
                                                            expanded>
                                                        <option v-for="(option, index) in contas"
                                                                :value="option.id"
                                                                :name="index"
                                                                :key="`${option.id}_${index}`">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                            </b-field>
                                        </ValidationProvider>
                                        <div class="panel control is-flex is-flex-direction-column"
                                             v-if="props.row.isSaved && (props.row.especieDeRecebimentoSelecionada == 2 || props.row.especieDeRecebimentoSelecionada == 3)">

                                            <b-select :placeholder="$t('CONTASRECEBERCADASTRO.BANCO')"
                                                      v-model="props.row.chequeBanco"
                                                      expanded>
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>

                                            <div class="is-flex control">
                                                <b-input type="text"
                                                        :placeholder="$t('CONTASRECEBERCADASTRO.AGENCIA')"
                                                        v-model="props.row.chequeAgencia" 
                                                        style="max-width: 40%"/>
    
                                                <b-input type="text"
                                                        :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERAUTORIZACAO')"
                                                        v-model="props.row.autorizacao" />
                                            </div>

                                            <b-select :placeholder="$t('CONTASRECEBERCADASTRO.BANDEIRA')"
                                                      v-model="props.row.bandeira.id">
                                                <option v-for="(option) in bandeiras"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                        </div>
                                        <div class="panel"
                                             v-if="props.row.especieDeRecebimentoSelecionada == 4 ||props.row.especieDeRecebimentoSelecionada == 6 ">
                                            <label class="label">{{$t('CONTASRECEBERCADASTRO.DADOS')}}</label>
                                            <b-select :placeholder="$t('CONTASRECEBERCADASTRO.BANCO')"
                                                      v-model="props.row.chequeBanco">
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                            <input type="text"
                                                   :placeholder="$t('CONTASRECEBERCADASTRO.AGENCIA')"
                                                   v-model="props.row.chequeAgencia"
                                                   class="input" />
                                            <b-input type="text"
                                                     placeholder="Número"
                                                     v-model="props.row.chequeNumero" />
                                        </div>
                                        <div class="panel"
                                             v-if="props.row.especieDeRecebimentoSelecionada == 5">
                                            <label class="label">{{$t('CONTASRECEBERCADASTRO.DADOS')}}</label>
                                            <b-select :placeholder="$t('CONTASRECEBERCADASTRO.BANCO')"
                                                      v-model="props.row.chequeBanco">
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                            <input type="text"
                                                   :placeholder="$t('CONTASRECEBERCADASTRO.AGENCIA')"
                                                   v-model="props.row.chequeAgencia"
                                                   class="input" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p v-if="props.row.contaCorrente.id" class="help">
                                            {{$t('CONTASRECEBERCADASTRO.CONTACORRENTEDESTINO')}}: <b>{{ props.row.contaCorrente.nome }}</b>
                                        </p>
                                        <div v-if="props.row.especieDeRecebimento != null && (props.row.Id != 0 || this.pagamentoGuia == null || !this.pagamentoGuia) && props.row.especieDeRecebimento.id !== 1 ">
                                            <p v-if="props.row.chequeBanco" class="help">
                                                {{$t('CONTASRECEBERCADASTRO.BANCO')}}: <b>{{ props.row.chequeBanco }}</b>
                                            </p>
                                            <p v-if="props.row.chequeAgencia" class="help">
                                                {{$t('CONTASRECEBERCADASTRO.AGENCIA')}}: <b>{{ props.row.chequeAgencia }}</b>
                                            </p>
                                            <p v-if="props.row.chequeNumero" class="help">
                                                {{$t('CONTASRECEBERCADASTRO.NUMERO')}}: <b>{{ props.row.chequeNumero }}</b>
                                            </p>
                                            <p v-if="props.row.autorizacao" class="help">
                                                {{$t('CONTASRECEBERCADASTRO.AUTORIZACAO')}}: <b>{{ props.row.autorizacao }}</b>
                                            </p>
                                            <p v-if="props.row.bandeira" class="help">
                                                {{$t('CONTASRECEBERCADASTRO.BANDEIRA')}}: <b> {{props.row.bandeira.nome || props.row.bandeira.text}} </b>
                                            </p>
                                        </div>
                                    </div>
                                </b-table-column>

                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.RECEBIMENTO')">
                                    <b-datetimepicker v-if="(props.row.recebido || props.row.situacao == 2) && isInRole('data-de-recebimento-editavel')"
                                                      v-model="props.row.dataDeRecebimento"
                                                      icon="calendar-today"
                                                      expanded>
                                    </b-datetimepicker>
                                     <b-datetimepicker  v-else-if="(props.row.recebido || props.row.situacao == 2)"
                                                        disabled
                                                        v-model="props.row.dataDeRecebimento"
                                                        icon="calendar-today"
                                                        expanded>
                                    </b-datetimepicker>
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.PAGOPOR')">
                                    <b-input type="text"
                                             v-model="props.row.pagoPor" />
                                </b-table-column>

                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.BOLETO')">
                                    <div v-if="props.row.especieDeRecebimentoSelecionada === 6 && props.row.situacao === 1">
                                        <b-field :label="$t('CONTASRECEBERCADASTRO.CONTROLEINTERNO')">
                                            <div>
                                                <b-input type="text"
                                                         v-model="props.row.nossoDocumento" />
                                                <p class="control">                                            
                                                        <span v-if="!props.row.boletoEmitido ">
                                                            <button type="button" size="is-small" class="button is-success"                                                            
                                                                    @click.prevent.stop="gerarBoleto(props.row)">                                                                                                                
                                                                    {{$t('CONTASRECEBERCADASTRO.GERARBOLETO')}}
                                                            </button>                                                                                                                        
                                                        </span>                                                    
                                                        <span v-else class="is-inline">   
                                                            <div class="buttons has-addons is-centered ">

                                                                <b-tooltip :label="$t('CONTASRECEBERCADASTRO.REEMITIRBOLETO')" multilined>   
                                                                    <b-button :title="$t('CONTASRECEBERCADASTRO.REEMITIRBOLETO')"
                                                                    @click="reemitirBoleto(props.row)" size="is-small" icon-left="refresh"></b-button>                                                                                                                                                
                                                                </b-tooltip>

                                                                <b-tooltip :label="$t('CONTASRECEBERCADASTRO.VISUALIZARBOLETO')" multilined>   
                                                                    <b-button :title="$t('CONTASRECEBERCADASTRO.VISUALIZARBOLETO')"
                                                                    @click="visualizarBoleto(props.row)" size="is-small" icon-left="download"></b-button>                                                                                                                                                                                        
                                                                </b-tooltip>

                                                                <b-tooltip :label="$t('CONTASRECEBERCADASTRO.CANCELARBOLETO')" multilined>   
                                                                    <b-button :title="$t('CONTASRECEBERCADASTRO.CANCELARBOLETO')"
                                                                    @click="cancelarBoleto(props.row)" size="is-small" icon-left="close-circle" type="has-text-danger"></b-button>                                                                                                                                                                                                                                                        
                                                                </b-tooltip>                                                                

                                                            </div>                                 
                                                            <br>
                                                        </span>                                                       

                                                </p>
                                            </div>
                                        </b-field>
                                    </div>
                                </b-table-column>

                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.SITUACAO')">
                                    <template v-if=" model.quantidadeExames >= 0 &&
                                        model.convenio.tipoConvenio && (model.convenio.tipoConvenio.faturaAberta || !model.convenio.tipoConvenio.faturaAberta) || !model.convenio.tipoConvenio">
                                        <div v-if=" props.row.situacao === 1 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-light" size="is-small">{{$t('CONTASRECEBERCADASTRO.PENDENTE')}}</b-button>
                                            <b-button v-if="model.convenio.tipoConvenio && !model.convenio.tipoConvenio.reembolso" type="button is-tag is-primary"
                                                      size="is-small"
                                                      @click="analisarParcela( props.row, props.row.situacao, props.row.index )">
                                                {{$t('CONTASRECEBERCADASTRO.ANALISAR')}}
                                            </b-button>

                                            <b-button type="button is-tag is-success"
                                                      size="is-small"
                                                      icon-left="check"
                                                      v-if="isInRole('receber-pagar-parcela')"
                                                      @click="receberParcela(props.row, props.row.situacao, props.row.index) "></b-button>
                                            <b-button type="button is-tag is-danger"
                                                      size="is-small"
                                                      icon-left="close"
                                                      v-if=" isInRole('receber-cancelar-parcela') && (props.row.podeCancelar != null ? props.row.podeCancelar : true)" 
                                                      @click="cancelarParcela( props.row,props.row.situacao, props.row.index) "></b-button>
                                        </div>
                                        <div v-if=" props.row.situacao === 2 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-light" size="is-small">
                                                <span v-if="props.row.dataDeRecebimento" class="help">
                                                    {{$t('CONTASRECEBERCADASTRO.RECEBIDOEM')}}
                                                    {{ props.row.dataDeRecebimento | data }} por
                                                    <b v-if="props.row.funcionarioRecebimento">
                                                        {{props.row.funcionarioRecebimento.nome }}
                                                    </b>
                                                </span>
                                            </b-button>
                                            <div class="panel-heading">
                                                <b-button type="button is-tag is-danger"
                                                          size="is-small"
                                                          icon-left="close"
                                                          v-if="isInRole('receber-cancelar-parcela')"
                                                        @click="cancelarParcelaPaga( props.row,props.row.situacao, props.row.index) ">{{$t('CONTASRECEBERCADASTRO.CANCELAR')}}</b-button>
                                                <b-button type="button is-primary"
                                                          size="is-small"
                                                          @click="imprimirRecibo(props.row)">{{$t('CONTASRECEBERCADASTRO.IMPRIMIRRECIBO')}}</b-button>
                                            </div>
                                        </div>
                                        <div v-if=" props.row.situacao === 3 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-danger" size="is-small">{{$t('CONTASRECEBERCADASTRO.CANCELADO')}}</b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 4 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-danger" size="is-small">{{$t('CONTASRECEBERCADASTRO.PAGAMENTOCANCELADO')}}</b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 5 && !props.row.recebido && !props.row.pagamentoCancelado">
                                            <b-button type="button is-tag is-light" size="is-small">{{$t('CONTASRECEBERCADASTRO.LIBERADO')}}</b-button>
                                            <b-button type="button is-tag is-success"
                                                      size="is-small"
                                                      icon-left="check"
                                                      v-if="isInRole('receber-pagar-parcela')"
                                                      @click="receberParcela(props.row,props.row.situacao,props.row.index )"></b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 6 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-warning" size="is-small">{{$t('CONTASRECEBERCADASTRO.ANALISE')}}</b-button>
                                            <b-button type="button is-tag is-primary"
                                                      size="is-small"
                                                      @click="liberarParcela(props.row,props.row.situacao,props.row.index)"> {{$t('CONTASRECEBERCADASTRO.LIBERAR')}}
                                            </b-button>
                                            <b-button type="button is-tag is-danger"
                                                      size="is-small"
                                                      @click="rejeitarParcela( props.row, props.row.situacao, props.row.index ) "> {{$t('CONTASRECEBERCADASTRO.REJEITAR')}}
                                            </b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 7 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <div class="columns">
                                                <div class="column">
                                                    <b-button type="button is-tag is-danger"
                                                              size="is-small">{{$t('CONTASRECEBERCADASTRO.REJEITADO')}}</b-button>
                                                </div>
                                                <div class="column">
                                                    <b-select v-if="props.row.rejeitado"
                                                              required
                                                              :placeholder="$t('CONTASRECEBERCADASTRO.PLACEHOLDERSELMOTIVO')"
                                                              class="is-fullwidth"
                                                              v-model="props.row.motivoRejeitado">
                                                        <option v-for="item in motivosRejeicao"
                                                                :key="item.id"
                                                                :value="item.id">
                                                            {{ item.descricaoInterno }}
                                                        </option>
                                                    </b-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="props.row.recebido">
                                            <b-button type="button is-tag is-success" size="is-small">{{$t('CONTASRECEBERCADASTRO.RECEBIDO')}}</b-button>
                                        </div>
                                        <div v-if="props.row.pagamentoCancelado">
                                            <b-button type="button is-tag is-danger" size="is-small">{{$t('CONTASRECEBERCADASTRO.PAGAMENTOCANCELADO')}}</b-button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <b-button type="button is-light" size="is-small">{{$t('CONTASRECEBERCADASTRO.PENDENTE')}}</b-button>
                                    </template>
                                </b-table-column>

                                <b-table-column  v-slot="props" field="dados" label=" ">
                                    <div v-if="props.row.id > 0">
                                        <modalDadosParcelaReceber :tabela="'Receber'" :chave="model.id" :parcela="props.row" v-if="model.id"></modalDadosParcelaReceber>
                                    </div>
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <div class="tile is-child notification is-warning"
                                     v-if="mostrarErroBoleto && erroCadastro.length">
                                    <span v-if="erroCadastro.length">*{{$t('CONTASRECEBERCADASTRO.ERROGERARBOLETO')}}</span>
                                    <ul class="">
                                        <li v-for="erro in erroCadastro" :key="erro" class="help">
                                            <span>{{ erro }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>
                <article class="tile is-child box"
                    v-if="model.consumiveis != null && model.consumiveis.length > 0">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         @click="isOpenConsumiveis = !isOpenConsumiveis; $forceUpdate();"> {{$t('CONTASRECEBERCADASTRO.CONSUMIVEIS')}}
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenConsumiveis"
                                expanded>
                        
                        <b-table :data="model.consumiveis"
                            striped
                            hoverable
                            class="fullwidth"
                            expanded>
                            <template>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.PEDIDO')">
                                    <a target="_blank" :href="'/Estoque/PedidoInterno/' + props.row.pedidoInternoItem.pedidoInterno.id">
                                        {{props.row.pedidoInternoItem.pedidoInterno.id}}
                                    </a>
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.CONSUMIVEL')">
                                    {{ props.row.pedidoInternoItem.consumivel.nome }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.QUANTIDADE')">
                                    {{ props.row.pedidoInternoItem.quantidade }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.PRECOUNITARIO')">
                                    {{ props.row.precoUnitario | currency }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.PRECO')">
                                    {{ props.row.precoUnitario * props.row.pedidoInternoItem.quantidade | currency }}
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <th colspan="3"></th>
                                <th>{{$t('CONTASRECEBERCADASTRO.TOTAL')}}:</th>
                                <th class="has-text-right">
                                    {{ model.totalConsumiveis | currency }}
                                </th>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>
                <article v-if="model.quantidadeExames > 0" class="tile is-child box">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         @click="listarExames()">
                        {{$t('CONTASRECEBERCADASTRO.LISTAEXAMES')}}
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenExames"
                                expanded>
                        <b-table :data="model.guiaExames"
                                 striped
                                 hoverable
                                 class="fullwidth"
                                 expanded>
                            <template >
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.GUIA')">
                                    <router-link :to="{ name: 'guia', params: { id: props.row.guiaExame.guiaExameId.guiaId }, }">{{ props.row.guiaExame.guiaExameId.guiaId }}
                                    </router-link>
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.ITEM')">
                                    {{ props.row.guiaExame.item }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.EXAME')">
                                    <b-button type="is-light">
                                        {{ props.row.guiaExame.exame.apelido }}
                                    </b-button>
                                </b-table-column>
                                <b-table-column v-if="model.temConvenioPadrao" v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.CONVENIO')">
                                    <b-button type="is-light">
                                        {{ props.row.guiaExame.guia.convenio.nome }}
                                    </b-button>
                                </b-table-column>
                                <b-table-column field="">
                                    <template v-slot:default="props">
                                    <div v-if="!props.row.removido">
                                        <b-button size="is-small"
                                                  :type="{ 'button is-light': props.row.situacao === 1, 'button is-success': props.row.situacao === 2, 'button is-danger': props.row.situacao === 3, 'button is-warning': props.row.situacao === 4,}">{{ props.row.situacao | situacao }}</b-button>
                                        <b-button size="is-small"
                                                  type="button is-tag is-danger"
                                                  icon-left="close"
                                                  v-if="props.row.situacao === 1 && !model.notaFiscal_Id"
                                                  @click="glosarExame(props.row, props.row.index)"></b-button>
                                        <b-tooltip v-else-if="props.row.situacao == 1" :label="$t('CONTASRECEBERCADASTRO.BLOQUEARBOTOES')">
                                            <b-button size="is-small"
                                                    type="button is-tag is-danger"
                                                    icon-left="close"
                                                    disabled></b-button>
                                        </b-tooltip>
                                        <b-button size="is-small"
                                            type="button is-tag is-warning"
                                            v-if="props.row.situacao === 1 && isInRole('receber-alteravalorpago') && !model.notaFiscal_Id"
                                            @click="faturarParcialExame(props.row)">P</b-button>
                                        <b-tooltip v-else-if="props.row.situacao == 1" :label="$t('CONTASRECEBERCADASTRO.BLOQUEARBOTOES')">
                                            <b-button size="is-small"
                                                    type="button is-tag is-warning"
                                                    disabled>P</b-button>
                                        </b-tooltip>

                                        <b-button size="is-small"
                                                  type="button is-tag is-success"
                                                  v-if="isInRole('receber-alteravalorpago') && (props.row.situacao === 1)"
                                                  @click="faturarExame(props.row)">R</b-button>
                                        <b-tooltip :label="$t('CONTASRECEBERCADASTRO.REVERTER')" >
                                            <b-button size="is-small"
                                                    :id="'reverterAcao_' + props.row.id"
                                                    type="button is-tag is-info"
                                                    icon-left="undo"
                                                    v-if="exibirButton(props.situacao, props.row)"
                                                    @click="reverterAcao(props.row, props.row.situacao)"></b-button>
                                        </b-tooltip>
                                    </div>
                                    <div v-else>
                                        <b-button size="is-small" :type="'button is-light'">{{$t('CONTASRECEBERCADASTRO.REMOVIDO')}}</b-button>
                                    </div>
                                </template>
                                <template v-slot:header>
                                    <div>
                                        {{$t('CONTASRECEBERCADASTRO.SITUACAO')}}
                                        <b-button size="is-small"
                                                class="ml-4"
                                                type="button is-tag is-success"
                                                v-if="model.guiaExames.some(p => p.situacao === 1 || p.situacao === 4) && isInRole('receber-alteravalorpago')"
                                                @click="faturarTodosExames()">
                                        {{ $t('CONTASRECEBERCADASTRO.RECEBERTODOS') }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.VALORRECEBIDO')">
                                    <div class="columns">
                                        <div class="column is-4">
                                            <b-field>
                                                <p class="control">
                                                    <button class="button is-light" type="button">
                                                        R$
                                                    </button>
                                                </p>
                                                <input class="input"
                                                    type="number"
                                                    :class="{ 'is-danger': hasError(props.row.id) }"
                                                    :disabled="isInRole('receber-alteravalorpago') && props.row.situacao !== 2 ? false : true"
                                                    step=".01"
                                                    v-model="props.row.valorPago"
                                                    @blur="atualizarValorPago(props.row)" 
                                                    :ref="`valorPagoInput_${props.row.id}`"
                                                    :style="{ width: '200px' }"                                                    
                                                />
                                            </b-field>
                                        </div>
                                    </div>
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.PRECO')" numeric>
                                    <span style="text-decoration: line-through"
                                          v-if="props.row.situacao === 3">{{ precoDeduzidoComRecebimentoDoLotePai(props.row) | currency }}</span>
                                    <span v-else>{{ precoDeduzidoComRecebimentoDoLotePai(props.row) | currency }}</span>
                                </b-table-column>
                                <b-table-column v-slot="props" field=""
                                                label="">
                                    <div v-if="model.situacaoReceber == 1 && !props.row.removido">
                                    <b-button icon-left="delete"
                                            size="is-small"
                                            type="is-danger"
                                            @click.prevent.stop="removerExame(props.row)"></b-button>
                                    </div>
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <th>
                                    <b-button class="mr-1" type="is-primary"
                                            @click="glosarGuia()"
                                            v-if="model.situacaoReceber == 2">
                                        {{$t('CONTASRECEBERCADASTRO.GLOSARGUIA')}}
                                    </b-button>
                                    <b-button type="is-success"
                                            @click="incluirGuia()"
                                            v-if="model.situacaoReceber == 1">
                                        {{$t('CONTASRECEBERCADASTRO.INCLUIRGUIA')}}
                                    </b-button>
                                    <b-button class="mr-1" type="is-primary"
                                        @click="abrirModalGerarLoteGlosa()"
                                        v-if="podeGerarLoteGlosa"
                                    >
                                        {{$t('CONTASRECEBERCADASTRO.GLOSALOTERGUIA')}}
                                    </b-button>
                                    <b-button tag="router-link" target="_blank" type="is-primary"
                                            :to="{ name: 'contas-receber', params: { id: model.receberIdGlosa }}"
                                            v-if="model.receberIdGlosa"
                                        >
                                        {{model.receberIdGlosa}}
                                    </b-button>
                                </th>
                                <th class="has-text-danger">
                                    <span v-if="valorTotalExamesGlosados > 0">{{$t('CONTASRECEBERCADASTRO.GLOSAS')}}:</span>
                                </th>
                                <th class="has-text-danger">
                                    <span v-if="valorTotalExamesGlosados > 0">
                                        {{ valorTotalExamesGlosados | currency }}
                                    </span>
                                </th>
                                <th colspan="1" v-show="totalPago > 0"></th>
                                <th v-show="totalPago > 0">{{$t('CONTASRECEBERCADASTRO.TOTALRECEBIDO')}}: {{ totalPago | currency }}</th>
                                <th colspan="2" v-show="totalPago == 0"></th>
                                <!-- <th class="has-text-right" v-show="totalPago > 0">
                                    {{ totalPago | currency }}
                                </th> -->
                                <th v-if="model.temConvenioPadrao"></th>
                                <th class="is-flex is-justify-content-end">{{$t('CONTASRECEBERCADASTRO.TOTAL')}}: {{ valorTotalExamesSemGlosa | currency }}</th>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>
                <article class="tile is-child box" v-show="mostrarImpostos"
                    v-if="model.impostos != null && model.impostos.length > 0">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         @click="isOpenImpostos = !isOpenImpostos; $forceUpdate();"> {{$t('CONTASRECEBERCADASTRO.IMPOSTOS')}}
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenImpostos"
                                expanded>
                        
                        <b-table :data="model.impostos"
                            striped
                            hoverable
                            class="fullwidth"
                            expanded>
                            <template>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.ID')">
                                    {{ props.row.id }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.IMPOSTO')">
                                    {{ props.row.imposto.nome }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.ALIQUOTA')">
                                    {{ props.row.aliquota }}
                                </b-table-column>
                                <b-table-column  v-slot="props" field="" :label="$t('CONTASRECEBERCADASTRO.VALOR')">
                                    {{ calcularValorImposto(props.row) | currency }}
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <th colspan="2"></th>
                                <th>{{$t('CONTASRECEBERCADASTRO.TOTAL')}}</th>
                                <th class="has-text-right">
                                    {{ calcularTotalGeralImpostos() | currency }}
                                </th>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>

                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('CONTASRECEBERCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back()">
                                {{$t('CONTASRECEBERCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style scoped>
    .btn-action {
        margin: 0.2rem;
    }

    .label-parcela-cancelada {
        padding-top: 0.25rem;
    }

    .collapse-content {
        width: 100% !important;
    }

    .panel .control {
        max-width: 210px;
    }
</style>

<script>
    import { mapGetters, mapState } from "vuex";
    import titlebar from "@/components/titlebar.vue";
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
    import modalDadosParcelaReceber from "@/components/financeiro/modalDadosParcelaReceber.vue";
    import modalCancelarFatura from "@/components/financeiro/modalCancelarFatura.vue"
    import modalGerarLoteGlosa from "@/components/financeiro/modalGerarLoteGlosa.vue";
    import modalLoteGlosaCriado from "@/components/financeiro/modalLoteGlosaCriado.vue";
    import searchIdName from "@/components/searchIdName.vue";
    import moment from "moment";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    versao: null,
                    convenio: {
                        id: 0,
                        tissVersao: null,
                    },
                    contaCorrente: {
                        id: null,
                    },
                    paciente: { id: 0 },
                    funcionario: { id: 0 },
                    localDeOrigem: { id: 0 },
                    unidade: { id: 0 },
                    cancelada: false,
                    observacoes: null,
                    categoriaFinanceira: { id: null, registroNomes: null },
                },
                contas: [],
                salvandoDados: false,
                pagamentoGuia: null,
                permiteGeracao: false,
                isOpenParcelas: true,
                isOpenExames: false,
                isOpenConsumiveis: false,
                isOpenImpostos: false,
                totalExames: 0,
                qntdeParcelas: 0,
                erroCadastro: [],
                mostrarErroBoleto: true,
                especies: [],
                valorNaoBate: false,
                unidades: [],
                bancos: [],
                valorParcelado: false,
                valorTotalParcelas: 0,
                alterouParcelas: false,
                temNotaFiscal: false,
                isLoading: false,
                notaFiscalConsulta: false,
                notaFiscal: null,
                bandeiras: [],
                descontoConvenioGuia: 0,
                desconto: 0,
                motivosRejeicao: [],
                motivosVencimentoAlterado: [],
                motivosDescontoParcela: [],
                valorTotalExamesSemGlosa: 0,
                valorTotalExamesGlosados: 0,
                agendamentoDashboard: this.$route.params.agendamentoDashboard,
                validaRecalculo: false,
                descontoTotal: 0,
                parcelaArredondada: false,
                parcelaRecebida: [],
                totalExameGlosaAtual: 0,
                totalPago: 0,
                precoRestante: 0,
                totalExamesParciais: 0,
                totalNaoPago: 0,
                guiaExamesAux: [],
                errors: {},
                reverterGlosa: false,
                reverterRecebido: false,
                clickedItems: [],
                recebido: [],
                recebidoParcial: [],
                glosado: [],
                valorMaiorTotal: false,
                totalImposto: 0,
                examesDesconto: 0,
            };
        },
        components: {
            titlebar,
            historicoAlteracoes,
            searchIdName,
            modalDadosParcelaReceber,
        },
        filters: {
            situacao: (valor) => {
                if (!valor) return null;

                switch (valor) {
                    case 1:
                        return "Aguardando";
                    case 2:
                        return "Recebido";
                    case 3:
                        return "Glosado";
                    case 4:
                        return "Rec. Parcial";
                }
            },
            especieRecebimento: (valor) => {
                if (!valor) return null;

                switch (valor) {
                    case 1:
                        return "Dinheiro";
                    case 2:
                        return "Cartão de Crédito";
                    case 3:
                        return "Cartão de Débito";
                    case 4:
                        return "Chque";
                    case 5:
                        return "Transferência Bancária";
                    case 6:
                        return "Boleto";
                }
            },
        },
        created() {
            this.loadAsyncData();
        },
        computed: {
            loteGlosa() {
                return this.model.receberIdGlosa > 0
            },
            exibirButton() {
                return (situacao, row) => {
                    const exibirButton = row.situacao !== 1 && this.clickedItems.includes(row.id);
                    
                    this.$nextTick(() => {
                        const buttonElement = document.getElementById(`reverterAcao_${row.id}`);
                        if (buttonElement) {
                            if(situacao == 3){
                                buttonElement.style.display = 'none';
                            } else {
                                buttonElement.style.display = exibirButton ? 'inline' : 'none';
                            }
                        }
                    });
                    return exibirButton;
                };
            },
            totalImpostos() {
                let total = 0;
                if (this.model.impostos != null && this.model.impostos.length > 0) {
                    this.model.impostos
                        .filter(element => element.imposto.nome != 'IR')
                        .forEach(element => {
                            total += (this.totalExames + this.model.totalConsumiveis) * (element.aliquota / 100);
                        });
                }
                return total ?? 0;
            },
            mostrarImpostos() {
                return this.totalImpostos > 10;
            },
            precoDeduzidoComRecebimentoDoLotePai()
            {
                const loteGlosa = this.loteGlosa
                return  (row) => {
                    if (loteGlosa)
                    {
                        return row.valorAReceber
                    }
                    return row?.guiaExame?.preco.toFixed(2);
                }
            },
            precoGlosaParcial()
            {
                return (row) => {
                    return this.precoDeduzidoComRecebimentoDoLotePai(row) - row.valorPago
                }
            },
            totalRecebidoParcialDoLotePai()
            {
                return this.model.guiaExames.reduce((totalRecebidoParcialDoLotePai, item) => {
                    const valorPagoParcialNoLotePai = item?.referenciaRecebimentoParcial?.valorPago
                    if (valorPagoParcialNoLotePai) return totalRecebidoParcialDoLotePai + valorPagoParcialNoLotePai
                }, 0)
            },
            somaDeTodosExames(){
                return this.model.guiaExames.reduce((somaTotalDosExames, item) => {
                    const preco = parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(item));
                    const soma = parseFloat(somaTotalDosExames);
                    const total = soma + preco;
                    return total.toFixed(2);
                }, 0)
            },
            somaDeTodosExamesGlosadosTotalmente()
            {
                return this.model.guiaExames.reduce((somaTotalDosExamesGlosados, item) => {
                    if (item.situacao == 3) return somaTotalDosExamesGlosados + this.precoDeduzidoComRecebimentoDoLotePai(item)
                    return somaTotalDosExamesGlosados
                },0)
            },
            somaDeTodosExamesGlosadosParcialmente()
            {
                return this.model.guiaExames.reduce((somaDeTodosExamesGlosadosParcialmente, item) => {
                    if (item.situacao == 4) return somaDeTodosExamesGlosadosParcialmente + this.precoGlosaParcial(item)
                    return somaDeTodosExamesGlosadosParcialmente
                },0)
            },
            somaDeTodasGlosas()
            {
                return this.somaDeTodosExamesGlosadosTotalmente + this.somaDeTodosExamesGlosadosParcialmente
            },
            somaDeTodosExamesFaturados()
            {
                return this.model.guiaExames.reduce((somaDeTodosExamesFaturados, item) => {
                    if (item.situacao == 2) return somaDeTodosExamesFaturados + item.valorPago
                    return somaDeTodosExamesFaturados
                },0)
            },
            somaDeTodosExamesAguardando()
            {
                return this.model.guiaExames.reduce((somaDeTodosExamesFaturados, item) => {
                    if (item.situacao == 1) return somaDeTodosExamesFaturados + this.precoDeduzidoComRecebimentoDoLotePai(item)
                    return somaDeTodosExamesFaturados
                },0)
            },
            titleStack() {
                return [
                    "Financeiro",
                    this.$t('CONTASRECEBERCADASTRO.CONTASRECEBER'),
                    this.model.id > 0 ? this.model.id : this.$t('SISTEMA.NOVO'),
                ];
            },
            ...mapState(["unidadeId", "config", "integradores", "usuario"]),
            ...mapGetters(["isInRole"]),
            podeGerarLoteGlosa() {
                const regras = this.obterRegrasParaCriarLoteGlosa(['GLOSA_INTEGRAL', 'GLOSA_PARCIAL', 'NAO_COMPUTADO']);
                return this.model.situacaoReceber == 2 && this.model.guiaExames.some(x => {
                    return regras.reduce((candidataParaNovoLote, regra) => {
                        if (candidataParaNovoLote) return true;
                        return candidataParaNovoLote || regra.executar(x);
                    }, false)
                })
            }
        },       
        methods: {
            async loadAsyncData() {
                if (
                    (this.$route.params.id || this.$route.params.guiaId) &&
                    (this.$route.params.id > 0 || this.$route.params.guiaId > 0)
                ) {
                    this.isLoading = true;
                    let url = "/api/financeiro/receber?id=" + this.$route.params.id;

                    if (this.$route.params.guiaId) {
                        //carregar guia particular
                        this.pagamentoGuia = true;
                        url =
                            "/api/financeiro/ReceberGuiaParticular?guiaId=" +
                            this.$route.params.guiaId +
                            "&condicaoPagamento=" +
                            this.$route.params.condicaoPagamento;
                    }

                    this.$http
                        .get(url)
                        .then((res) => {
                            if (res.data.listarDocumentosDaGuia) {
                                this.$router.push({
                                    name: "lista-contas-receber",
                                    query: {
                                        guiaId: this.$route.params.guiaId,
                                    },
                                });
                                return;
                            }
                            

                            const model = res.data?.item;
                            model.data = model?.data
                                ? moment(model.data)?.toDate()
                                : new Date();
                            this.contas = res.data.contasCorrentes;
                            this.pagamentoGuia = res.data.pagamentoGuia;
                            this.especies = res.data.especies;
                            this.unidades = res.data.unidades;
                            this.bandeiras = res.data.bandeira;
                            this.descontoConvenioGuia = res.data.desconto;
                            this.descontoTotal = res.data.totalDescontos
                            this.desconto = res.data.descontoContaReceber != null ? res.data.descontoContaReceber : 0;
                            this.bancos = res.data.bancos;
                            this.totalExames = res.data.totalExames;
                            if (this.pagamentoGuia) {
                                this.valorTotalExamesSemGlosa = this.totalExames ?? this.valorTotalParcelas;
                            }

                            if(this.$route.params.guiaId && this.descontoTotal != 0) {
                                this.descontoTotal = res.data.desconto;
                            }

                            model.parcelas = model.parcelas?.map((p, index) => {
                                const valor = parseFloat(Math.round(p.valor * 100) / 100).toFixed(
                                    2
                                );
                                const valorReferencia = valor.toString();

                                const espRecebimento =
                                    p.especieDeRecebimento != null ? p.especieDeRecebimento.id : 0;

                                const contaCorrente = 
                                    p.contaCorrente != null ? p.contaCorrente.id : 0;

                                return {
                                    ...p,
                                    dataDeVencimento: new moment(p.dataDeVencimento)?.toDate(),
                                    dataDeVencimentoInicio: new moment(
                                        p.dataDeVencimento
                                    )?.toDate(),
                                    dataDeRecebimento:
                                        p.dataDeRecebimento != null
                                            ? new moment(p.dataDeRecebimento)?.toDate()
                                            : null,
                                    especieDeRecebimentoSelecionada: this.especies.filter(
                                        (x) => x.id == espRecebimento
                                    )[0]?.id,
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    valor: valor,
                                    valorReferencia: valorReferencia,
                                    bandeira: p.bandeira != null ? p.bandeira : this.bandeiras[0],
                                    contaCorrenteSelecionada: this.contas.filter(
                                        (c) => c.id == contaCorrente
                                    )[0]?.id,
                                    contaCorrente: p.contaCorrente ?? {id: null},
                                };
                            });
                            if (!model.convenio) {
                                model.convenio = { id: 0 };
                            }

                            if (!model.categoriaFinanceira) {
                                model.categoriaFinanceira = { id: 0 };
                            }

                            this.model = model;
                            this.verificarErrosBoleto();
                            if (this.valorTotalParcelas == 0) {
                                this.model.parcelas
                                    ?.filter((x) => x.situacao !== 3 && x.situacao !== 2)
                                    .forEach(
                                        (p) => (this.valorTotalParcelas += parseFloat(p.valor))
                                    );
                                this.valorTotalParcelas = this.valorTotalParcelas.toFixed(2);
                            }

                            if (this.model.notaFiscal_Id) {
                                this.temNotaFiscal = true;
                            } else {
                                this.temNotaFiscal = false;
                            }

                            this.qntdeParcelas = this.model.parcelas.filter((x) => x.situacao !== 2 && x.situacao !== 3).length
                           
                            this.isLoading = false;
                            let valorImposto = 0.0

                            if(this.model.impostos != null && this.model.impostos.length > 0) {
                                this.isOpenExames = !this.isOpenExames;
                                this.$forceUpdate();
                                const valorTotal = this.totalExames || this.valorTotalParcelas
                                valorImposto = this.calculaImpostoParaRecalculoParcela(this.totalPago ?? valorTotal);
                            }
                            this.listarExames();
                            this.examesDesconto = this.model.guiaExames.filter(x => x.situacao != 3 && x.guiaExame.exameDesconto > 0).map(x => x.guiaExame.exameDesconto).reduce((a, b) => a + b, 0);

                            if(this.model.id == 0 || this.model.parcelaArredondada == false) {
                                let setValor = 0.0;

                                if( this.model.id !== 0 && this.model.parcelaArredondada == false) {
                                    this.model.parcelaArredondada = true;
                                }

                                if (this.totalExames && this.totalExames > 0 && this.valorTotalParcelas == 0) {
                                    let descontoGuia = parseFloat(this.descontoConvenioGuia);
                                    if(this.examesDesconto > 0) {
                                        descontoGuia = 0;
                                    }
                                    setValor = (((parseFloat(this.totalExames) - valorImposto) - descontoGuia)).toFixed(2);
                                    
                                } else {
                                    if(parseFloat(this.valorTotalParcelas) == this.totalExames){
                                        this.valorTotalParcelas -= valorImposto;
                                    }
                                    setValor = (parseFloat(this.valorTotalParcelas)).toFixed(2);
                                }
                                this.arredondarParcelas(setValor)
                            }

                            if( this.desconto == null ||  this.desconto == 0 ||  this.desconto == undefined){
                                this.desconto = this.model.desconto;
                            }

                            if( this.descontoConvenioGuia == null ||  this.descontoConvenioGuia == 0 ||  this.descontoConvenioGuia == undefined){
                                this.descontoConvenioGuia = this.model.descontoConvenioGuia;
                            }
                        })
                        .finally(() => {   

                            if(this.unidadeId && this.model.id == 0) {
                                const contaCorrespondente = this.contas.find(c => {
                                    if (c.contaCorrenteUnidade && c.contaCorrenteUnidade.length > 0) {
                                        const conta = c.contaCorrenteUnidade.find(ccu => ccu.id === this.unidadeId);
                                        return conta;   
                                    }
                                });
                                
                                this.model.parcelas = this.model.parcelas.map(p => {
                                    p.contaCorrente.id = contaCorrespondente ? contaCorrespondente.id : null
                                    return p;
                                })
                                this.$forceUpdate();
                            }
                        })                            
                        .catch((ex) => {
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = true;
                    this.$http
                        .get("/api/financeiro/receber")
                        .then((res) => {
                            const novoItem = {};
                            novoItem.id = 0;
                            novoItem.data = new Date();
                            novoItem.funcionario = this.model.funcionario;
                            novoItem.notaFiscal = null;
                            novoItem.funcionario = res.data.item?.funcionario;

                            novoItem.categoriaFinanceira = { id: 0 };
                            novoItem.unidade = { id: 0 };
                            novoItem.convenio = { id: 0, tissVersao: null };
                            novoItem.paciente = { id: 0 };
                            novoItem.localDeOrigem = { id: 0 };
                            novoItem.quantidadeExames = 0;
                            
                            this.contas = res.data.contasCorrentes;
                            this.pagamentoGuia = res.data.pagamentoGuia;
                            this.especies = res.data.especies;
                            this.unidades = res.data.unidades;
                            this.bandeiras = res.data.bandeira;
                            this.descontoConvenioGuia = res.data.desconto;
                            this.desconto = res.data.descontoContaReceber != null ? res.data.descontoContaReceber : 0;
                            this.descontoTotal = res.data.totalDescontos;
                            this.bancos = res.data.bancos;

                            novoItem.contaCorrente = res.data.item?.contaCorrente;
                            novoItem.parcelas = [
                                {
                                    id: 0,
                                    situacao: 1,
                                    valor: 0.0,
                                    valorReferencia: 0.0,
                                    dataDeVencimento: new Date(),
                                    dataDeVencimentoInicio: new Date(),
                                    dataDeRecebimento: new Date(),
                                    especieDeRecebimentoSelecionada: this.especies[0]?.id,
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    bandeira: this.bandeiras[0],
                                    contaCorrenteSelecionada: this.contas[0]?.id,
                                    contaCorrente: { id: null }

                                },
                            ];
                            this.model = novoItem;

                            this.qntdeParcelas = this.model.parcelas.filter((x) => x.situacao !== 2 && x.situacao !== 3).length

                            this.isLoading = false;

                            this.listarExames();
                            if(this.model.impostos != null && this.model.impostos.length > 0) {
                                this.isOpenExames = !this.isOpenExames;
                                this.$forceUpdate();
                            }

                            if( this.desconto == null ||  this.desconto == 0 ||  this.desconto == undefined){
                                this.desconto = this.model.desconto;
                            }

                            if( this.descontoConvenioGuia == null ||  this.descontoConvenioGuia == 0 ||  this.descontoConvenioGuia == undefined){
                                this.descontoConvenioGuia = this.model.descontoConvenioGuia;
                            }
                        })
                        .finally(() => {   

                            if(this.unidadeId) {
                                const contaCorrespondente = this.contas.find(c => {
                                    if (c.contaCorrenteUnidade && c.contaCorrenteUnidade.length > 0) {
                                        const conta = c.contaCorrenteUnidade.find(ccu => ccu.id === this.unidadeId);
                                        return conta;   
                                    }
                                });
                                
                                this.model.parcelas = this.model.parcelas.map(p => {
                                    p.contaCorrente.id = contaCorrespondente ? contaCorrespondente.id : null
                                    p.contaCorrenteSelecionada = contaCorrespondente ? contaCorrespondente.id : null;
                                    return p;
                                })
                                this.$forceUpdate();
                            }
                        })                         
                        .catch((e) => {
                            console.error(e);
                            this.isLoading = false;
                        });
                }

                this.$http.get("/api/search/motivosrejeicao").then((res) => {
                    this.motivosRejeicao = res.data;
                });

                this.$http.get("/api/search/motivosvencimentoalterado").then((res) => {
                    this.motivosVencimentoAlterado = res.data;
                });

                this.$http.get("/api/search/motivosdescontoparcela").then((res) => {
                    this.motivosDescontoParcela = res.data;
                });
            },
            validarValorTotalDasParcelas() {
                this.valorNaoBate = false;
                //remover verificação até ajustar erros
                return this.valorNaoBate;

                //if ((this.valorTotalParcelas != null && this.alterouParcelas) || this.validaRecalculo) {
                //    let valorTotalParcelas = 0;
                //    this.model.parcelas
                //    ?.filter((x) => x.situacao !== 3)
                //    .forEach((p) => {
                //        valorTotalParcelas += parseFloat(p.valor);
                //    });
                //    valorTotalParcelas = valorTotalParcelas.toFixed(2); 
                //    let valorImposto = 0;
                //    let valorTotal = this.model.totalExames  ?? 0;
                //    let valorDesconto = this.descontoConvenioGuia != 0 && this.descontoConvenioGuia != null && this.descontoConvenioGuia != undefined 
                //        ? this.descontoConvenioGuia : this.desconto;

                //    if(this.model.impostos != null && this.model.impostos.length > 0) {
                //        valorImposto = this.calcularTotalGeralImpostos();
                //    }

                //    //caso todas parcelas canceladas, atribui o valor total - descontos, ao totalparcelas, para não travar o salvamento.
                //    if(this.model.parcelas.filter((x) => x.situacao !== 3).length == 0) {
                //        valorTotalParcelas -= parseFloat(valorDesconto);
                //        valorTotalParcelas = valorTotalParcelas.toFixed(2)
                //    }
                    
                //    if(this.model.guiaExames != null && this.model.guiaExames.filter((x) => x.situacao !== 2 || x.situacao !== 3).length == 0) {
                //        valorDesconto = 0;
                //    }
                    
                //    if(this.model.totalConsumiveis) {
                //        valorTotal += this.model.totalConsumiveis;
                //    }

                //    if(valorDesconto != 0) {
                //        if(valorTotalParcelas > 0)
                //            valorTotalParcelas = (parseFloat(valorTotalParcelas) - parseFloat(valorDesconto)).toFixed(2);
                //        if(valorTotal > 0 && this.examesDesconto == 0)
                //            valorTotal -= parseFloat(valorDesconto);
                //    }
                    
                //    valorImposto = valorImposto.toFixed(2);
                //    if(valorImposto && valorTotal < 0){
                //        valorTotal += parseFloat(valorImposto);
                //    }

                //    if(valorTotalParcelas == 0){
                //        this.valorTotalParcelas = 0;
                //    }
                    
                //    if (this.model.totalExames && this.model.totalExames > 0) {
                //        const calculo = (parseFloat(valorTotalParcelas) + parseFloat(valorImposto) + parseFloat(valorDesconto)).toFixed(2);
                //        let calculoValorTotal = 0.0;
                //        valorTotal = valorTotal.toFixed(2);
                //        this.model.guiaExames.filter((x) => x.situacao === 4).forEach(e => {
                //            valorTotal -= this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago;
                //        });
                //        if(this.totalExameGlosaAtual > 0 && (this.model.parcelas.filter((x) => x.situacao === 1).length != 0 || this.model.parcelas.filter((x) => x.situacao === 1).length != 1) ){
                //            calculoValorTotal = (parseFloat(valorTotal) - parseFloat(this.totalExameGlosaAtual) - calculo).toFixed(2);
                //            this.totalExameGlosaAtual = 0;
                //        }
                //        else{   
                //            calculoValorTotal = ((parseFloat(valorTotal)) - calculo).toFixed(2)    
                //        }

                //        if (Math.abs(calculoValorTotal) != 0.0 && calculoValorTotal != this.parcelaRecebida.valor){
                //            this.valorNaoBate = true;
                //        }
                //    } else {
                //        if (parseFloat(this.valorTotalParcelas) - (parseFloat(valorTotalParcelas)) != 0.0 && this.guiaExames != null && this.guiaExames.length > 0) {
                //            this.valorNaoBate = true;
                //        }
                //    }
                //}

                //return this.valorNaoBate;
            },
            validateData(model) {
                const errorIds = model.guiaExames
                    .filter((x) => x.valorPago === "" && !this.errors[x.id])
                    .map((e) => {
                        this.$set(this.errors, e.id, true);
                        return e.id;
                    });

                if (errorIds.length > 0) {
                    this.$buefy.toast.open({
                        duration: 6000,
                        message: this.$t('CONTASRECEBERCADASTRO.ALERTASALVARECEBER'),
                        type: "is-danger",
                        queue: false,
                    });

                    errorIds.forEach((errorId, index) => {
                        const inputRef = this.$refs[`valorPagoInput_${errorId}`];
                        if (inputRef) {
                            inputRef.classList.add('error-highlight');
                            if (index === 0) {
                                inputRef.focus();
                            }
                        }
                    });
                    return false;
                }

                return true; 
            },
            async onSubmit(redirect = true) {
                if(this.valorMaiorTotal){
                    return;
                }

                this.validaRecalculo = true;
                const isParcelaInvalida = this.validarValorTotalDasParcelas();
                this.$forceUpdate();
                if (!isParcelaInvalida) {
                     this.isLoading = true;

                    const newItem = this.getItem();

                newItem.parcelas = await Promise.all(this.model.parcelas.map(async (p) => {
                    if (p.contaCorrente && p.contaCorrente.id !== null) {
                        const response = await this.$http.get(`/api/financeiro/ContaCorrente?id=${p.contaCorrente.id}`);
                        p.contaCorrente = response.data.item;
                    }
                    if(p.especieDeRecebimentoSelecionada !== null) {
                        p.especieDeRecebimento = this.especies.find(e => e.id === p.especieDeRecebimentoSelecionada);
                    }
                    return p;
                }));
                                
                    const listaParcelasCanceladasPagas = this.getListaParcelasCanceladasPagas();

                    const listaParcelasCanceladas = this.getListaParcelasCanceladas();

                    const alterouParcelas = this.alterouParcelas;

                    const pagamentoGuia = this.pagamentoGuia;

                    const desconto = this.desconto ?? 0;

                    const cancelada = !this.model.cancelada ? false : this.model.cancelada;

                    const observacoes = ( this.model.observacoes !=null && this.model.observacoes !=undefined ) ?  this.model.observacoes : '';


                    if (!this.model.categoriaFinanceira.id) {
                        this.$buefy.dialog.alert({
                            title: "Categoria financeira obrigatória",
                            message: "Especifique uma categoria financeira para continuar.",
                            type: "is-warning",
                            hasIcon: true,
                        });
                        this.isLoading = false;
                        return;
                    }

                    if (this.model.id == 0 && !this.model.unidade.id) {
                        this.$buefy.dialog.alert({
                            title: "Unidade obrigatório",
                            message: "Especifique uma unidade para continuar.",
                            type: "is-warning",
                            hasIcon: true,
                        });
                        this.isLoading = false;
                        return;
                    }

                    if (this.model.parcelas?.some(p => p.contaCorrente.id === null || !p.contaCorrente)) {
                        this.$buefy.dialog.alert({
                            title: this.$t('CONTASRECEBERCADASTRO.CONTACORRENTEOBRIG'),
                            message: this.$t('CONTASRECEBERCADASTRO.CONTACORRENTMSG'),
                            type: "is-warning",
                            hasIcon: true,
                        });
                        this.isLoading = false;
                        return;
                    }

                    if (
                        this.model.paciente &&
                        (this.model.paciente.id == 0 || this.model.paciente.id == null)
                    ) {
                        newItem.paciente = null;
                    }
                    
                    newItem.NOTAFISCAL_ID = this.model.notaFiscal_Id;
                    
                    return this.$http
                        .post(
                            `/api/financeiro/receber?cancelando=${listaParcelasCanceladas}&cancelandoPagas=${listaParcelasCanceladasPagas}&alterouParcelas=${alterouParcelas}&pagamentoGuia=${pagamentoGuia}&desconto=${desconto}&cancelada=${cancelada}&observacoes=${observacoes}`,
                            newItem
                        )
                        .then(() => {
                            this.isLoading = false;

                            if (redirect)
                                if (this.pagamentoGuia) {
                                    this.$router.push({
                                        name: "workflow",
                                        params: {
                                            id: this.$route.params.guiaId,
                                            agendamentoDashboard: this.agendamentoDashboard,
                                        },
                                    });
                                } else {
                                    this.$router.push({ name: "financeiro" });
                                }
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            console.error(err);
                            if (err.body?.errors) {
                                if (!this.validateData(this.model)) {
                                    return;
                                } else {
                                    Object.keys(err.body.errors).forEach((x) => {
                                        this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                    });
                                }
                            } else {
                                const mensagem =
                                    err.status === 500
                                        ? "Foi encontrado um erro no servidor ao salvar o registro"
                                        : "Erro ao salvar registro";
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: mensagem,
                                    type: "is-danger",
                                    queue: false,
                                });
                            }
                        });
                }
            },
            download(itemName, extension = "pdf") {
                const loteGlosa = this.loteGlosa

                let url = `/api/financeiro/${itemName.toLowerCase()}?id=${this.model.id}&loteGlosa=${loteGlosa}`;

                if(this.descontoTotal != null)
                url += `&descontoTotal=${this.descontoTotal.toFixed(2)}`;

                if(this.descontoConvenioGuia != null && this.model.totalDescontos != null)
                    url += `&descontoConvenioGuia=${this.descontoConvenioGuia != null ? this.descontoConvenioGuia : this.model.totalDescontos}`;

                window.open(url, "_blank");
            },
            fecharFatura() {
                this.$buefy.dialog.confirm({
                    title: "Fatura",
                    message:
                        "Deseja realmente fechar a fatura? Você não poderá fazer alterações após essa ação.",
                    confirmText: "Confirmar",
                    type: "is-info",
                    hasIcon: true,
                    cancelText: "Cancelar",
                    onConfirm: () => {
                        this.model.situacaoReceber = 2;
                    },
                });
            },
            calculaImpostoParaRecalculoParcela(total) {
                let valorImposto = 0.0;
                this.model.impostos.filter(i => i.imposto.nome != "IR").forEach(imposto => valorImposto += imposto.aliquota / 100);

                return (total + this.model.totalConsumiveis) * valorImposto;
            },
            dividirParcela() {
                this.$buefy.dialog.prompt({
                    message: `Deseja dividir a parcela em quantas vezes?`,
                    inputAttrs: {
                        type: "number",
                        placeholder: "",
                        value: 0,
                        max: 999,
                        min: 0,
                        title: "O campo precisa ser informado",
                    },
                    trapFocus: true,
                    confirmText: "OK",
                    cancelText: "Cancelar",
                    onConfirm: (value) => {
                        this.valorParcelado = true;
                        this.alterouParcelas = true;
                        //pegar a data da parcela
                        let dataParcela = moment().toDate();
                        if (this.examesDesconto > 0) {
                            this.desconto = 0;
                            this.descontoConvenioGuia = 0;
                        }

                        const especieDeRecebimento = {
                            id: this.model.parcelas[0].especieDeRecebimentoSelecionada,
                        }
                        
                        const contaCorrente = {
                            id: this.model.parcelas[0].contaCorrenteSelecionada,
                        }

                        const contaCorrenteLista = this.contas.find(c => c.id === contaCorrente.id);

                        let valor = 0;
                        let setValor = 0.0;                     
                        let valorImposto = 0;
                        let valorConsumivel = 0;

                        if(this.model.impostos != null && this.model.impostos.length > 0) { 
                            if(this.totalPago != null && this.totalPago > 0){
                                valorImposto = this.calculaImpostoParaRecalculoParcela(this.totalPago);
                            }
                            else {
                                valorImposto = this.calculaImpostoParaRecalculoParcela(this.model.totalExames);      
                            }           
                        }

                        if(this.descontoConvenioGuia == null || this.descontoConvenioGuia == 0 || this.descontoConvenioGuia == undefined) {
                            this.descontoConvenioGuia = 0;
                        }


                        if(this.model.totalExames == null || this.model.totalExames == 0) {
                            valorImposto = 0;
                        }

                        if(this.model.totalConsumiveis) {
                            valorConsumivel += this.model.totalConsumiveis;
                            valorConsumivel = valorConsumivel.toFixed(2);
                        }

                        const totalExamesFaturados = this.model.guiaExames.filter((x) => x.situacao === 2).reduce((a, b) => a + this.precoDeduzidoComRecebimentoDoLotePai(b), 0);                
                        let totalExamesParciais = 0;

                        this.model.guiaExames.filter((x) => x.situacao === 4).forEach((exame) => {
                            totalExamesParciais += Number(exame.valorPago);
                        });   

                        const parcelasAux = this.model.parcelas.filter((x) => x.situacao !== 1);
                        const parcelasPagas = this.model.parcelas.filter((x) => x.situacao === 2);
                        if (this.model.parcelas?.length) {
                            dataParcela = moment(
                                this.model.parcelas[0]?.dataDeVencimento
                            ).toDate();
                            if (this.model.totalExames && this.model.totalExames > 0) {
                                if(this.descontoConvenioGuia && this.descontoConvenioGuia > 0){
                                    valor = (((parseFloat(this.model.totalExames) + parseFloat(valorConsumivel) - valorImposto) - parseFloat(this.descontoConvenioGuia)) / value).toFixed(2);
                                    setValor = (((parseFloat(this.model.totalExames) + parseFloat(valorConsumivel)- valorImposto) - parseFloat(this.descontoConvenioGuia))).toFixed(2);
                                }else {
                                    valor = (((parseFloat(this.model.totalExames) + parseFloat(valorConsumivel) - valorImposto) - parseFloat(this.desconto)) / value).toFixed(2);
                                    
                                    setValor = ((parseFloat(this.model.totalExames) + parseFloat(valorConsumivel) - valorImposto) - parseFloat(this.desconto)).toFixed(2);
                                }
                                this.model.guiaExames.filter((x) => x.situacao === 4).forEach(e => {
                                    valor -= this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago;
                                    setValor -= this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago;
                                });
                            } else {
                                if(this.descontoConvenioGuia && this.descontoConvenioGuia > 0){
                                    valor = ((parseFloat(this.valorTotalParcelas) - valorImposto) / value).toFixed(2);
                                    setValor = ((parseFloat(this.valorTotalParcelas) - valorImposto)).toFixed(2);
                                }else {
                                    valor = ((parseFloat(this.valorTotalParcelas) - valorImposto) / value).toFixed(2);
                                    setValor = ((parseFloat(this.valorTotalParcelas) - valorImposto)).toFixed(2);
                                }
                            }
                        }
                        if(parcelasAux.length > 0 && parcelasPagas.length > 0) {
                            parcelasPagas.forEach(p => {
                                setValor -= parseFloat(p.valor);
                            });
                        }
                        if (value) {   
                            this.model.parcelas = [];
                            const totalParcelas = value;
                            const contaCorrespondente = this.contas.find(c => {
                                if (c.contaCorrenteUnidade && c.contaCorrenteUnidade.length > 0) {
                                    const conta = c.contaCorrenteUnidade.find(ccu => ccu.id === this.unidadeId);
                                    return conta;   
                                }
                            });
                            const contaCorrenteSelecionada = contaCorrente.id ?? (contaCorrespondente ? contaCorrespondente.id : null)
                            for (let p = 0; p < totalParcelas; p++) {
                                const dataDeVencimento = moment(dataParcela).add("M", p).toDate();                
                                this.model.parcelas.push({
                                    id: 0,
                                    dataDeVencimento: dataDeVencimento,
                                    dataDeVencimentoInicio: dataDeVencimento,
                                    dataDeRecebimento: null,
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    situacao: 1,
                                    especieDeRecebimentoSelecionada: especieDeRecebimento.id,
                                    valor: valor,
                                    bandeira: {
                                        id: this.bandeiras[0].value,
                                    },
                                    podeCancelar: totalParcelas > 1,
                                    isSaved: true,
                                    contaCorrenteSelecionada: contaCorrenteSelecionada,
                                    contaCorrente: contaCorrenteLista,                                     
                                });                               
                            }                           
                            if(parcelasAux.length > 0) {
                                this.model.parcelas = this.model.parcelas.concat(parcelasAux);
                            }

                            this.qntdeParcelas = this.model.parcelas.length;
                        }

                        if(this.model.parcelas.length >= 1) {
                            this.arredondarParcelas(setValor);
                        } 
                    },
                });
            },
            arredondarParcelas(total) {
                const valorParcela = parseFloat(total / this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).length).toFixed(2);
                const valorPrimeiraParcela = total - (valorParcela * (this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).length - 1));
                
                let countParcelas = 0;
                this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).forEach(parc => {
                    if(countParcelas == 0) {
                        parc.valor =  valorPrimeiraParcela.toFixed(2)
                        countParcelas += 1;
                    }else {
                        parc.valor = valorParcela
                    }

                })
            },
            modalCancelarFatura() {
                if (this.model.notaFiscal != null && this.model.notaFiscal_Id != null && this.model.notaFiscal != "(cancelada)"  && this.model.notaFiscal != "(em cancelada)") {
                    this.$buefy.dialog.alert({
                        title: "Fatura",
                        message:
                            "Não é possível cancelar uma fatura com nota fiscal emitida!",
                        type: "is-warning",
                        hasIcon: true,
                    });
                } else {                    
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalCancelarFatura,
                        props: {
                            model: this.model,
                            isLoading: this.isLoading,
                        },
                        events: {
                            loadReceber: this.loadAsyncData
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
            },

            verificarErrosBoleto() {
                this.erroCadastro = [];
                this.mostrarErroBoleto = true;
                const pacienteTemCPF = this.model.paciente == null || this.model.paciente?.cpf;
                const pacienteTemCEP = this.model.paciente == null || this.model.paciente?.cep;
                const pacienteTemNome = this.model.paciente == null || this.model.paciente?.nome;
                const convenioTemCNPJ = this.model.convenio == null || this.model.convenio?.cnpj;
                const convenioTemCEP = this.model.convenio == null || this.model.convenio?.cep;
                const convenioTemNome = this.model.convenio == null || this.model.convenio?.nome;
                const contaCorrenteTemCNPJ = this.model.contaCorrente?.cnpj;
                const contaCorrenteTemNome = this.model.contaCorrente?.nome;
                const contaCorrenteTemAgencia = this.model.contaCorrente?.agencia;
                const contaCorrenteTemNumero = this.model.contaCorrente?.numero;
                const contaCorrenteTemCarteira = this.model.contaCorrente?.carteira;
                const contaCorrenteTemNossoNumero = this.model.contaCorrente?.nossoNumero;

                if (!pacienteTemCPF) {
                    this.erroCadastro.push("O paciente precisa ter CPF cadastrado");
                }
                if (!pacienteTemCEP) {
                    this.erroCadastro.push("O paciente precisa ter CEP cadastrado");
                }
                if (!pacienteTemNome) {
                    this.erroCadastro.push("O paciente precisa ter Nome cadastrado");
                }
                if (!convenioTemCNPJ) {
                    this.erroCadastro.push("O convenio precisa ter CNPJ cadastrado");
                }
                if (!convenioTemCEP) {
                    this.erroCadastro.push("O convenio precisa ter CEP cadastrado");
                }
                if (!convenioTemNome) {
                    this.erroCadastro.push("O convenio precisa ter Nome cadastrado");
                }
                if (!contaCorrenteTemCNPJ) {
                    this.erroCadastro.push("A conta corrente deve ter CNPJ cadastrado");
                }
                if (!contaCorrenteTemNome) {
                    this.erroCadastro.push("A conta corrente deve ter Nome cadastrado");
                }
                if (!contaCorrenteTemAgencia) {
                    this.erroCadastro.push("A conta corrente deve ter Agência cadastrada");
                }
                if (!contaCorrenteTemNumero) {
                    this.erroCadastro.push("A conta corrente deve ter Número cadastrado");
                }
                if (!contaCorrenteTemCarteira) {
                    this.erroCadastro.push("A conta corrente deve ter Carteira cadastrada");
                }
                if (!contaCorrenteTemNossoNumero) {
                    this.erroCadastro.push("A conta corrente deve ter Nosso Número cadastrado");
                }
            },
            cancelarParcela(item, situacao, index) {
                item.parcelaCancelada = true;
                item.situacao = 3;

                if(item.parcelaCancelada && item.situacao == 3 && !item.recebido) {
                  this.qntdeParcelas = this.model.parcelas.filter((x) => !x.parcelaCancelada && !x.recebido).length;
                }

                this.recalcularParcelas(item, index);
            },
            receberParcela(item, situacao, index) {
                if (item.dataDeRecebimento == null) {
                    item.dataDeRecebimento = new Date();
                }
                this.parcelaRecebida = item;
                const nomeEspecie = this.especies.filter((x) => x.id == item.especieDeRecebimentoSelecionada)[0].nome;
                
                item.especieDeRecebimento = {
                    id: item.especieDeRecebimentoSelecionada,
                    nome: nomeEspecie,
                }

                item.funcionarioRecebimento = {
                    id: this.usuario.funcionarioId,
                    nome: this.usuario.funcionarioNome
                }
                item.recebido = true;
                item.situacao = 2;

                if(item.situacao == 2 && !item.parcelaCancelada) {
                  this.qntdeParcelas = this.model.parcelas.filter((x) => !x.parcelaCancelada && x.situacao != 2).length;
                }
            },
            liberarParcela(item, situacao, index) {
                item.situacao = 5;
                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoAnalise != null
                ) {
                    const date = new Date(item.dataDeVencimento);
                    date.setDate(
                        date.getDate() + this.model.convenio.tipoConvenio.prazoAnalise
                    );
                    item.dataDeVencimento = date;
                }
            },
            analisarParcela(item, situacao, index) {
                item.situacao = 6;
                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoPendente != null
                ) {
                    const date = new Date(item.dataDeVencimento);
                    date.setDate(
                        date.getDate() + this.model.convenio.tipoConvenio.prazoPendente
                    );
                    item.dataDeVencimento = date;
                }
            },
            dataDeVencimentoAlterada(item) {
                if (
                    item.dataDeVencimento.getTime() != item.dataDeVencimentoInicio.getTime()
                ) {
                    item.vencimentoAlterado = true;
                }
            },
            rejeitarParcela(item, situacao, index) {
                item.situacao = 7;
                item.rejeitado = true;

                Date.prototype.addDays = function (days) {
                    const date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                };

                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoPendente != null
                ) {
                    this.model.parcelas.push({
                        id: 0,
                        dataDeVencimento: new Date().addDays(
                            this.model.convenio.tipoConvenio.prazoPendente
                        ),
                        dataDeVencimentoInicio: new Date().addDays(
                            this.model.convenio.tipoConvenio.prazoPendente
                        ),
                        recebido: false,
                        pagamentoCancelado: false,
                        situacao: 1,
                        especieDeRecebimentoSelecionada: this.especies[0].id,
                        valor: item.valor,
                        podeCancelar: true,
                    });
                }
            },
            cancelarParcelaPaga(item, situacao, index) {
                item.pagamentoCancelado = true;
                item.situacao = 3;
                this.recalcularParcelas(item, index);
            },
            imprimirRecibo(item) {
                window.open(`/api/financeiro/GeraRecibo?parcelaId=${item.id}&convenioId=${this.model.convenio.id}`, "_blank");
            },
            async reverterAcao(item, situacao){
                item.situacao = 1;

                switch (situacao) {
                    case 3:
                        this.totalExameGlosaAtual -= item.guiaExame.preco;
                        this.reverterGlosa = true;
                        break;

                    case 2:
                        this.totalPago -= item.valorPago;
                        this.reverterRecebido = true;
                        break;

                    case 4:
                        this.totalPago -= item.valorPago;
                        break;
                }

                const recebido = this.recebido.includes(item.id);
                const recebidoParcial = this.recebidoParcial.includes(item.id);
                const glosado = this.glosado.includes(item.id);

                if (recebido || recebidoParcial || glosado) {
                    await this.calcularValorImposto(this.model.impostos);
                    await this.recalcularParcelas(item);
                }

                this.recebido = this.recebido.filter(id => id !== item.id);
                this.recebidoParcial = this.recebidoParcial.filter(id => id !== item.id);
                this.glosado = this.glosado.filter(id => id !== item.id);
            },
            async glosarExame(item, index) {
                if(this.valorMaiorTotal){
                    return;
                }
                if (!this.clickedItems.includes(item.id)) {
                    this.clickedItems.push(item.id);
                }
                if (!this.glosado.includes(item.id)) {
                    this.glosado.push(item.id);
                }
                item.situacao = 3;
                item.novaGlosa = true;
                item.valorPago = 0
                this.totalExameGlosaAtual += this.precoDeduzidoComRecebimentoDoLotePai(item);
                await this.calcularValorImposto(this.model.impostos);
                await this.recalcularParcelas(item);
            },
            async faturarExame(item) {
                if(this.valorMaiorTotal){
                    return;
                }

                let valorPago = 0;
                let totalParcelas = 0;
                let valorNaoPago = 0;
                
                if (!this.clickedItems.includes(item.id)) {
                    this.clickedItems.push(item.id);
                }
                if (!this.recebido.includes(item.id)) {
                    this.recebido.push(item.id);
                }
                
                valorPago = parseFloat(valorPago) + parseFloat(item.valorPago);

                if(item.situacao !== 3){
                    if((valorPago < this.precoDeduzidoComRecebimentoDoLotePai(item) && item.valorPago !== 0) 
                        && item.situacao == 1 || item.valorPago == ""){
                        if(item.guiaExame.preco !== 0){
                            this.showToast(this.$t('CONTASRECEBERCADASTRO.ALERTARECEBER'), item.id);
                            this.recebido = [];
                            return;
                        }
                    }

                    this.model.parcelas.filter((x) => x.situacao == 1).forEach((p) => {
                        totalParcelas += parseFloat(p.valor);
                    });

                    valorNaoPago = this.precoDeduzidoComRecebimentoDoLotePai(item) - item.valorPago;
                    this.totalNaoPago += valorNaoPago;
                    this.totalPago += parseFloat(item.valorPago);
                    item.situacao = 2;
                    if(valorPago <= this.precoDeduzidoComRecebimentoDoLotePai(item)){
                        await this.arredondarParcelasFaturamento(totalParcelas);
                        await this.recalcularParcelas(item);
                    }
                }

            },
            async faturarParcialExame(item) {
                let valorPago = 0;
                let totalParcelas = 0;
                let valorNaoPago = 0;

                if(this.valorMaiorTotal){
                    return;
                }
                if (!this.recebidoParcial.includes(item.id)) {
                    this.recebidoParcial.push(item.id);
                }
                if (parseFloat(item.valorPago) === parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(item)) && item.situacao === 1) {
                    this.showToast(this.$t('CONTASRECEBERCADASTRO.ALERTARECEBERPARCIAL'), item.id);
                    this.recebidoParcial = [];
                    return;
                }
                
                if (!this.clickedItems.includes(item.id)) {
                    this.clickedItems.push(item.id);
                }

                if(item.valorPago > 0 ){
                    this.model.parcelas.filter((x) => x.situacao == 1).forEach((p) => {
                        totalParcelas += parseFloat(p.valor);
                    });
    
                    valorPago += item.valorPago;
                    valorNaoPago = this.precoDeduzidoComRecebimentoDoLotePai(item) - item.valorPago;
                    item.situacao = 4;
                    totalParcelas -=  parseFloat(valorNaoPago);
                    this.totalNaoPago += valorNaoPago;
                    this.totalPago += parseFloat(item.valorPago);
                    if(valorPago <= this.precoDeduzidoComRecebimentoDoLotePai(item)){
                        await this.arredondarParcelasFaturamento(totalParcelas);
                        await this.recalcularParcelas(item);
                    }
                }
            },
            showToast(message, itemId) {
                this.$buefy.toast.open({
                    duration: 6000,
                    message,
                    type: "is-danger",
                    queue: false,
                });

                this.$set(this.errors, itemId, true);

                const inputRef = this.$refs[`valorPagoInput_${itemId}`];
                if (inputRef) {
                    inputRef.focus();
                }
            },
            hasError(id) {
                return this.errors[id];
            },
            atualizarValorPago(item){
                if(item.valorPago > this.precoDeduzidoComRecebimentoDoLotePai(item)){
                    this.valorMaiorTotal = true;
                    this.showToast(this.$t('CONTASRECEBERCADASTRO.VALORPAGOALERTA', {0: item.valorPago, 1: this.precoDeduzidoComRecebimentoDoLotePai(item)}), item.id);
                    return;
                }
                this.valorMaiorTotal = false;
                this.$set(this.errors, item.id, false);
                const recebidoParcial = this.recebidoParcial.includes(item.id);

                if(!recebidoParcial && item.situacao == 4){
                    if(item.valorPago !== '' || item.valorPago !== undefined){
                        this.guiaExamesAux.filter(x => x.id === item.id).forEach(e => {
                            this.totalPago -= e.valorPago;      
                            if(item.valorPago != ''){
                                this.totalPago += parseFloat(item.valorPago);
                            }                          
                        });
                    }
                    this.guiaExamesAux = JSON.parse(JSON.stringify(this.model.guiaExames));
                    this.recebidoParcial = this.recebidoParcial.filter(id => id !== item.id);
                }                
            },
            async faturarTodosExames() {
                if(this.valorMaiorTotal){
                    return;
                }

                this.$buefy.dialog.confirm({
                    message: this.$t('CONTASRECEBERCADASTRO.MSGFATURAR'),
                    type: 'is-warning',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {            
                        if(!this.model.notaFiscal_Id){
                            this.model.guiaExames.filter((x) => x.situacao === 1).forEach(e => {
                                if(parseFloat(e.valorPago) == this.precoDeduzidoComRecebimentoDoLotePai(e)){
                                    this.faturarExame(e);
                                }
                                else if(parseFloat(e.valorPago) < this.precoDeduzidoComRecebimentoDoLotePai(e)){
                                    if(!this.model.notaFiscal_Id){
                                        this.faturarParcialExame(e);
                                    }
                                }
                            });
                        }
                    },
                });
            },
            arredondarParcelasFaturamento(totalParcelas) {
                totalParcelas = parseFloat(totalParcelas).toFixed(2);
                const parcelasPendentes = this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2);
                const numParcelas = parcelasPendentes.length;

                if (numParcelas > 0) {
                    const valorPrimeiraParcela = parseFloat(totalParcelas / numParcelas).toFixed(2);
                    const valorOutrasParcelas = valorPrimeiraParcela * (numParcelas - 1);

                    parcelasPendentes.forEach((parc, index) => {
                        if (index === 0) {
                            parc.valor = parseFloat(totalParcelas - valorOutrasParcelas).toFixed(2);
                        } else {
                            parc.valor = valorPrimeiraParcela;
                        }
                    });
                }
            },
            exibirParcelarValor() {
                return (
                    this.model.parcelas?.filter(
                        (p) => p.situacao == 1 
                    ).length > 0 && this.isInRole("receber-parcela-alterar-reparcelar")
                );
            },
            recalcularParcelas(item, index, desconto = 0) {
                const itemExameGlosado = item.guiaExame && item.situacao === 3;
                let valorTotal = 0;
                this.validaRecalculo = true;
                let recalculado = false;
                let valorTotalImposto = 0.0
                if(this.examesDesconto > 0){
                    this.desconto = 0;
                    this.descontoConvenioGuia = 0;
                }
                const valorDesconto = this.descontoConvenioGuia != 0 && this.descontoConvenioGuia != null && this.descontoConvenioGuia != undefined 
                        ? this.descontoConvenioGuia : this.desconto;
                
                if(this.model.impostos != null && this.model.impostos.length > 0) {
                    valorTotalImposto = this.calcularTotalGeralImpostos();
                }                           
                if(itemExameGlosado) {
                    this.totalExames = this.totalExames - this.precoDeduzidoComRecebimentoDoLotePai(item);
                } 
                else{
                    valorTotal -= this.valorTotalExamesGlosados;
                }
                if (!isNaN(this.totalExames)) {
                    valorTotal = parseFloat(this.totalExames + this.model.totalConsumiveis).toFixed(2);
                } else {
                    valorTotal = parseFloat(this.valorTotalParcelas).toFixed(2);
                }
               
                if(valorDesconto != 0 && this.descontoConvenioGuia !== 0 && (this.$route.params.guiaId == 0 || this.$route.params.guiaId == undefined)){
                    valorTotal -= parseFloat(valorDesconto);
                }

                if(valorTotalImposto != null && valorTotalImposto > 0) {
                    valorTotalImposto = parseFloat(valorTotalImposto).toFixed(2);
                    valorTotal -= Math.abs(valorTotalImposto);
                }
                
                this.model.guiaExames.filter((x) => x.situacao === 4).forEach(e => {
                    valorTotal -= this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago;
                });
                
                //Quando for recalculo de alteração no valor de alguma parcela
                if (item != null && item.situacao == 1 || (item.guiaExame != null && item.situacao !== 2)) {
                    if(this.reverterGlosa){
                        valorTotal = parseFloat(valorTotal) + parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(item));
                        this.totalExames += this.precoDeduzidoComRecebimentoDoLotePai(item);
                        this.reverterGlosa = false;
                    }
                    else if(this.reverterRecebido){
                        if(parseFloat(item.valorPago) != this.precoDeduzidoComRecebimentoDoLotePai(item)){
                            valorTotal += this.precoDeduzidoComRecebimentoDoLotePai(item) - parseFloat(item.valorPago);
                            this.totalExames += this.precoDeduzidoComRecebimentoDoLotePai(item) - parseFloat(item.valorPago);
                            this.model.totalExames += this.precoDeduzidoComRecebimentoDoLotePai(item) - parseFloat(item.valorPago);
                        }
                        this.reverterRecebido = false;
                    }
                    
                    this.model.parcelas
                        .forEach((p, i) => {
                            if(p.situacao == 2){
                                valorTotal -= parseFloat(p.valor);
                            }
                            
                            if (i != index && p.situacao == 1) {
                                if (this.model.parcelas.filter((x) => x.situacao == 1).length == 0) {
                                    p.valor = 0.0;
                                } else {
                                    p.valor = parseFloat(valorTotal / (this.model.parcelas.filter((x) => x.situacao == 1).length)).toFixed(2); 
                                    if(p.valor < 0) {
                                        p.valor = 0.0
                                    }  
                                }
                            }
                            else{                                
                                p.valor = parseFloat(p.valor).toFixed(2);
                                if(p.valor < 0) {
                                    p.valor = 0.0
                                } 
                            }
                        });
                    //Quando for recalculo de cancelamento de alguma parcela
                }
                else {
                    this.model.parcelas
                        .filter((x) => !x.parcelaCancelada)
                        .forEach((p, i) => {
                            if(p.situacao !== 2) {
                                p.valor = (this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).length > 0) ? 
                                ((valorTotal) / this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).length).toFixed(2) : 0;
                                if( this.model.guiaExames.every(x => x.situacao == 3)){
                                    p.valor = 0;
                                    this.model.totalExames = 0;
                                }
                            }
                            else {
                                recalculado = true;
                                if((item.guiaExame != null && item.situacao == 3) || item.situacao == 3 && (this.$route.params.guiaId == 0 || this.$route.params.guiaId == undefined))//quando for glosa ou cancelamento de parcela
                                {
                                    valorTotal -= parseFloat(p.valor);
                                }    
                            }
                        });
                }
                if(recalculado == true && 
                    this.model.parcelas.some((x) => x.situacao == 2 || x.situacao == 1 || x.situacao == 3) &&
                    this.$route.params.guiaId !== 0 || this.$route.params.guiaId !== undefined){
                    
                    let totalParcelasPendentes = 0;
                    let totalParcelasPagas = 0;
                    this.model.parcelas.filter(x => x.situacao == 2).forEach(p => {
                        totalParcelasPendentes += parseFloat(p.valor);
                    })
                    this.model.parcelas.filter(x => x.situacao == 1).forEach(p => {
                        totalParcelasPagas += parseFloat(p.valor);
                    })
                    if((totalParcelasPendentes + totalParcelasPagas - this.model.totalConsumiveis) > this.model.totalExames){
                        this.model.parcelas.filter(x => x.situacao == 2).forEach(p => {
                            valorTotal -= parseFloat(p.valor); 
                        })
                    }
                }
                if(this.model.parcelas.length > 1) {
                    this.arredondarParcelas(valorTotal);
                }
            },
            getItem(novaReceberGlosada) {
                const novoItem = {};

                novoItem.id = novaReceberGlosada != null ? 0 : this.model.id;
                novoItem.data = this.model.data; 
                novoItem.convenio = this.model.convenio?.id ? this.model.convenio : null;
                novoItem.paciente = this.model.paciente;
                novoItem.funcionario = this.model.funcionario;
                novoItem.localDeOrigem = this.model.localDeOrigem;
                novoItem.unidade = this.model.unidade;
                novoItem.categoriaFinanceira = this.model.categoriaFinanceira;
                novoItem.notaFiscal = this.model.notaFiscal;
                novoItem.contaCorrente = this.model.contaCorrente;
                novoItem.situacaoReceber = this.model.situacaoReceber;
                novoItem.receberIdGlosa = this.model.receberIdGlosa;
                novoItem.parcelaArredondada = this.model.parcelaArredondada;

                if(novaReceberGlosada){
                    novoItem.parcelasPai = {}
                }
                
                novoItem.parcelas = this.model.parcelas?.map((p) => {
                    const item = {
                        ...p,
                    };

                    const bandeiraId = p.bandeira.id || p.bandeira.value;
                    const bandeiraNome =  this.bandeiras.filter(x => x.value == bandeiraId)[0].text

                    item.bandeira = {
                        id: bandeiraId,
                        nome: bandeiraNome,
                    }
                                        
                    if (item.especieDeRecebimentoSelecionada) {
                        if (!item.especieDeRecebimento) {
                            item.especieDeRecebimento = {
                                id: item.especieDeRecebimentoSelecionada,
                            };
                        } else {
                            item.especieDeRecebimento.id = item.especieDeRecebimentoSelecionada;
                        }
                    }
                    
                    if(typeof item.bandeira !== 'object') {
                        item.bandeira = {
                           id: item.bandeira,
                        }
                    }

                    if (item.recebido) {
                        item.situacao = 2;
                    }

                    return item;
                });
                novoItem.guiaExames = novaReceberGlosada ? novaReceberGlosada : this.model.guiaExames?.map((g) => {
                    return {
                        ...g,
                    };
                });
                this.model.guiaExames = novoItem.guiaExames;
                return novoItem;
            },
            getListaParcelasCanceladas() {
                return this.model.parcelas
                    ?.filter((p) => p.parcelaCancelada)
                    .map((p) => {
                        return p.id;
                    })
                    ?.join(",");
            },
            getListaParcelasCanceladasPagas() {
                return this.model.parcelas
                    ?.filter((p) => p.pagamentoCancelado)
                    .map((p) => {
                        return p.id;
                    })
                    ?.join(",");
            },
            pacienteAlterado(event) {
                this.model.paciente = {
                    id: event.data.id,
                    nome: event.data.nome,
                    sexo: event.data.sexo,
                };
            },
            reemitirBoleto(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('CONTASRECEBERCADASTRO.MENSAGEMREEMITIRBOLETO'),
                    type: 'is-warning',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                        return this.gerarBoleto(item);
                    },
                                    
                });                
                
            },
            visualizarBoleto(item){
                const url =  this.config.urlBoletoTecnoSpeed + item.protocolo;
                window.open(url,'_blank');
            },
            cancelarBoleto(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('CONTASRECEBERCADASTRO.MENSAGEMCANCELARBOLETO'),
                    type: 'is-warning',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                            this.$http
                            .post(
                                `/api/financeiro/cancelarBoleto?parcelaId=${item.id}`
                            )
                            .then(res => res.data)
                            .then(data => {       
                                this.isLoading = true;             
                                setTimeout(() => this.loadAsyncData(),5000);                    
                            })
                            .catch(e => {
                                console.error(e.body);
                            });
                    },
                                    
                }); 
            },                        
            gerarBoleto(item) {
                this.$http
                .post(
                    `/api/financeiro/emitirBoleto?parcelaId=${item.id}&nossoDocumento=${item.nossoDocumento}`
                )
                .then(res => res.data)
                .then(data => {           
                    this.isLoading = true;         
                    setTimeout(() => this.loadAsyncData(),5000);                    
                })
                .catch(e => {
                    console.error(e.body);
                });
            },

            somaParcelas() {
                let total = 0;
                this.model.parcelas?.filter((x) => x.situacao !== 3)
                .forEach(
                    (p) => (total += parseFloat(p.valor.toString()))
                );
                return total.toFixed(2);
            },
            labelId(id) {                                
                return (id>0) ? id : '' ;
            },            
            especieSelecionada(item) {
                if (!item.chequeBanco) {
                    item.chequeBanco = this.bancos[0]?.value;
                }
                if (
                    item.especieDeRecebimentoSelecionada == 2 ||
                    item.especieDeRecebimentoSelecionada == 3
                ) {
                    if (!item.bandeira) {
                        item.bandeira = {
                            id: this.bandeiras[0]?.value,
                            nome: this.bandeiras[0]?.text,
                        };
                    }
                }
                this.$forceUpdate();
            },
            async contaCorrenteSelecionada(props){                
                await this.$http
                    .get(`/api/financeiro/ContaCorrente?id=${props.row.contaCorrenteSelecionada}`)
                    .then( res => {
                        props.row.contaCorrente = res.data.item
                    })

                    this.model.parcelas = this.model.parcelas.map((p, index) => {
                        if(index == props.index)
                        p.contaCorrente = props.row.contaCorrente
                    
                    return p
                })                
                 this.$forceUpdate();
            },
            aplicarDesconto() {
                this.$buefy.dialog.prompt({
                    message: `Desconto`,
                    inputAttrs: {
                        type: "text",
                        placeholder: "",
                        value: 0,
                        title: "O campo precisa ser informado"
                    },
                    trapFocus: true,
                    confirmText: "OK",
                    cancelText: "Cancelar",
                    onConfirm: (value) => {
                        const regex = /^[0-9.,%-]+$/;
                        if (!regex.test(value)) {
                            this.$buefy.toast.open({
                                duration: 4000,
                                message: `O valor inserido não é válido. Insira apenas números e %.`,
                                type: "is-danger",
                                queue: false,
                            });
                            return false;
                        }
                        this.desconto = value;

                        if (this.desconto != null) {
                            const porcentagem = this.desconto.indexOf("%") != -1;
                            const totalParcelasPagas = this.model.parcelas.filter(p => p.situacao === 2).reduce((total, p) => total + parseFloat(p.valor), 0);
                            if (porcentagem) {
                                let valorTotalImposto = 0;
                                if(this.model.impostos != null && this.model.impostos.length > 0) {
                                    valorTotalImposto = this.calcularTotalGeralImpostos();
                                }    
                                
                                this.desconto = this.desconto.replace("%", "").replace(",", ".");

                                if(this.model.convenio.descontoMaximo != null && this.desconto > this.model.convenio.descontoMaximo && !this.isInRole('desconto-permitir')) {
                                    this.desconto = 0;

                                    return this.$buefy.toast.open({
                                        duration: 4000,
                                        message:`O desconto informado é maior que o desconto máximo permitido no convênio ${this.model.convenio.descontoMaximo}%`,
                                        type: "is-danger",
                                        queue: false,
                                    });
                                }
                               
                                this.valorTotalParcelas -= totalParcelasPagas;
                                this.desconto = this.formatPrice(this.desconto);
                                let total = parseFloat(this.valorTotalParcelas) - parseFloat(this.valorTotalParcelas) * parseFloat(this.desconto) / 100;
                                total = total.toFixed(2);
                                this.model.parcelas?.filter((x) => x.situacao !== 3 && x.situacao !== 2).forEach((p) => {
                                    if (
                                        p.situacao != 2 &&
                                        p.situacao != 3 &&
                                        p.situacao != 4 &&
                                        p.situacao != 7
                                    ) {
                                        p.valor = total / this.model.parcelas.length;
                                        p.descontoAplicado = true;
                                    }
                                });

                                this.desconto =( parseFloat(this.valorTotalParcelas) - total).toFixed(2);
                                this.valorTotalParcelas = total;
                                this.totalExames = total;
                                this.totalExames -= this.model.totalConsumiveis;
                                this.totalExames = parseFloat(this.totalExames) + parseFloat(valorTotalImposto);

                                this.descontoTotal = this.desconto
                            } else {
                                this.desconto = this.desconto.replace(",", ".");
                                const descontoPercentual = Math.round((this.desconto / this.totalExames) * 100, 2)

                                if(this.model.convenio.descontoMaximo != null && descontoPercentual > this.model.convenio.descontoMaximo && !this.isInRole('desconto-permitir')) {
                                    this.desconto = 0;
                                    return this.$buefy.toast.open({
                                        duration: 4000,
                                        message: `O desconto informado é maior que o desconto máximo permitido no convênio ${this.model.convenio.descontoMaximo}%`,
                                        type: "is-danger",
                                        queue: false,
                                    });
                                }

                                this.valorTotalParcelas = parseFloat(this.valorTotalParcelas) - parseFloat(this.desconto) - totalParcelasPagas;
                                this.totalExames = parseFloat(this.totalExames) - parseFloat(this.desconto) - totalParcelasPagas;
                                this.model.parcelas?.filter((x) => x.situacao !== 3 && x.situacao !== 2).forEach((p) => {
                                    if (
                                        p.situacao != 2 &&
                                        p.situacao != 3 &&
                                        p.situacao != 4 &&
                                        p.situacao != 7
                                    ) {
                                        p.valor = (parseFloat(p.valor) - parseFloat(this.desconto) / this.model.parcelas.filter((x) => x.situacao !== 3 && x.situacao !== 2).length).toFixed(2);
                                        p.descontoAplicado = true;
                                        return false;
                                    }
                                });

                                this.descontoTotal = this.desconto
                            }
                        }
                        this.arredondarParcelas(this.valorTotalParcelas);
                        this.$forceUpdate();
                    },
                });
            },
            formatPrice(value) {
                const val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            emitirNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(`/api/financeiro/emitir?receberId=${this.model.id}&unidadeId=` + this.unidadeId )
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.error.message;
                            if (response.body.error.data && response.body.error.data.fields) {
                                this.fieldErrors = Object.entries(
                                    response.body.error.data.fields
                                );
                            }
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Erro ao emitir a nota fiscal: " + JSON.stringify(response.body),
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.documentos = response.body;
                            this.loadAsyncData();
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem = err.body;
                            this.$buefy.toast.open({
                                duration: 10000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            pdfNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/pdf?documentoId=${this.model.notaFiscal_Id}&clienteId=${this.model.convenio.id}&receberId=${this.model.id}&unidadeId=${this.model.unidade.id}`,
                        { responseType: "arraybuffer" }
                    )
                    .then((response) => {
                        this.isLoading = false;
                        const file = new Blob([response.data], { type: "application/pdf" });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao abrir pdf nota fiscal"
                                    : "Erro ao abrir pdf nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            cancelarNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/cancelar?documentoId=${this.model.notaFiscal_Id}&receberId=${this.model.id}&unidadeId=${this.model.unidade.id}`
                    )
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.message;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: response.body.error.message,
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.documentoCancelado = response.body;
                            this.loadAsyncData();
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao cancelar nota fiscal"
                                    : "Erro ao cancelar nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            consultarNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/consultar?documentoId=${this.model.notaFiscal_Id}&clienteId=${this.model.convenio.id}&receberId=${this.model.id}&unidadeId=${this.model.unidade.id}`
                    )
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.error.message;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Erro ao consultar a nota fiscal",
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.notaFiscalConsulta = true;
                            this.notaFiscal = response.body[0];
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao consultar nota fiscal"
                                    : "Erro ao consultar nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            glosarGuia() {
                this.$buefy.dialog.prompt({
                    title: "Guia",
                    message: "Confirme a guia que deseja glosar.",
                    inputAttrs: {
                        type: "number",
                        placeholder: "Guia",
                    },
                    confirmText: "Confirmar",
                    cancelText: "Cancelar",
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.model.guiaExames.forEach((x) => {
                            if (x.guiaExame.guia.id == res) {
                                this.glosarExame(x);
                            }
                        });
                    },
                });
            },
            abrirModalGerarLoteGlosa()
            {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalGerarLoteGlosa,
                    hasModalCard: true,
                    trapFocus: true,
                    props: {
                        existeCorrespondencia: (criterios) => {
                            const regras = this.obterRegrasParaCriarLoteGlosa(criterios)
                            const candidatos = this.obterCandidatosAoLoteGlosaDeAcordoComAsRegras(regras)
                            return candidatos.length > 0
                        }                                 
                    },
                    events: {
                        gerarLote: async (criterios) => {
                            try {
                                const valido = await this.$refs.observer.validate();
                                
                                if (!valido) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Não foi possível gerar lote glosa/não recebido, verifique os campos inválidos',
                                        type: "is-danger",
                                        queue: false,
                                    })
                                    return
                                }

                                await this.onSubmit(false)
                                this.model.guiaExames = []
                                await this.listarExames()
                                await this.glosarLoteGuia(criterios)
                            } catch (error) {
                                this.isLoading = false
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: error,
                                    type: "is-danger",
                                    queue: false,
                                });
                            }
                        },
                    },
                })
            },
            obterRegrasParaCriarLoteGlosa(criterios) {
                const listaDeRegras = [];

                for(const criterio of criterios)
                {
                    switch (criterio)
                    {
                        case 'GLOSA_INTEGRAL':
                            listaDeRegras.push({
                                regra: criterio,
                                executar: (exame) => {
                                    return exame.situacao == 3
                                }
                            })
                            break;
                        case 'GLOSA_PARCIAL':
                            listaDeRegras.push({
                                regra: criterio,
                                executar: (exame) => {
                                    return exame.situacao == 4 && (exame.valorPago > 0 && exame.valorPago < this.precoDeduzidoComRecebimentoDoLotePai(exame))
                                }
                            })
                            break;
                        case 'NAO_COMPUTADO':
                            listaDeRegras.push({
                                regra: criterio,
                                executar: (exame) => {
                                    return (exame.situacao == 1) && (exame.valorPago == 0 || exame.valorPago == null || exame.valorPago == '')
                                }
                            })
                            break;
                    }
                }

                return listaDeRegras;
            },
            obterCandidatosAoLoteGlosaDeAcordoComAsRegras(regras)
            {
                return this.model.guiaExames.filter(x => {
                    return regras.reduce((candidataParaNovoLote, regra) => {
                        if (candidataParaNovoLote) return true;
                        return candidataParaNovoLote || regra.executar(x);
                    }, false)
                });
            },
            async glosarLoteGuia(criterios) {
                const regras = this.obterRegrasParaCriarLoteGlosa(criterios);
                const regraGlosaParcial = this.obterRegrasParaCriarLoteGlosa(['GLOSA_PARCIAL']).shift();
                let guiasGlosadas = this.obterCandidatosAoLoteGlosaDeAcordoComAsRegras(regras);
                if(this.examesDesconto > 0){
                    this.desconto = 0;
                    this.descontoConvenioGuia = 0;
                }
                if (!guiasGlosadas.length)
                {
                    console.error(`Nenhum item de glosa foi encontrado de acordo com as regras`, guiasGlosadas)
                    throw new Error(`Ocorreu um erro ao tentar gerar lote glosa!`);
                }
                
                const preco = guiasGlosadas.map(x => {
                    if (regraGlosaParcial.executar(x)) return parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(x)) - parseFloat(x.valorPago);
                    return parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(x));
                }).reduce((sum, x) => parseFloat(sum) + parseFloat(x), 0);

                guiasGlosadas = guiasGlosadas.map(x => {
                    // Se é uma glosa parcial, cria um novo item no novo lote
                    // mantendo a referencia desse item também no lote atual
                    if (regraGlosaParcial.executar(x)) {
                        x.id = 0 
                        x.valorPago = 0
                    }
                    return x;
                })
                
                const newItem = this.getItem(guiasGlosadas);
     
                guiasGlosadas.forEach(x => {
                    x.situacao = 1
                });
                const valorDesconto = this.descontoConvenioGuia != 0 && this.descontoConvenioGuia != null && this.descontoConvenioGuia != undefined 
                    ? this.descontoConvenioGuia : this.desconto;

                newItem.receberIdGlosa = this.model.id
                newItem.data = new Date();   
                newItem.parcelas =[{
                    id: 0,
                    situacao: 1,
                    valorReferencia: 0.0,
                    valor: preco,
                    dataDeVencimento: new Date(),
                    dataDeVencimentoInicio: new Date(),
                    dataDeRecebimento: new Date(),
                    especieDeRecebimentoSelecionada: this.especies[0]?.id,
                    recebido: false,
                    pagamentoCancelado: false,
                    bandeira: this.bandeiras[0],
                }]  
                
                const valorImposto = this.calcularTotalGeralImpostos();
                this.valorTotalExamesGlosados -= preco;
                const valorGlosado = Math.abs(this.valorTotalExamesGlosados)
                const totalExamesAnterior = this.model.totalExames;
                this.model.totalExames  =  parseFloat((this.model.totalExames + this.model.totalConsumiveis)  - valorImposto - valorDesconto - valorGlosado).toFixed(2);
                const parcelaValor = this.model.totalExames  / this.model.parcelas?.length;
                newItem.parcelasPai = this.model.parcelas?.map((p) => {
                    const item = {
                        ...p,
                        receber: {
                            id: this.model.id
                        }, 
                    };
                   
                    return item;
                });
                
                try {
                    this.isLoading = true;
                    await this.$http.post('/api/financeiro/receber', newItem)
                    const respostaBuscaLoteGlosaRecemCriado = await this.$http.get(`/api/financeiro/ListarReceber?receberIdGlosa=${this.model.id}`)
                    const loteGlosaRecemCriado = respostaBuscaLoteGlosaRecemCriado.body.lista.shift()
                    this.isLoading = false;
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalLoteGlosaCriado,
                        hasModalCard: true,
                        trapFocus: true,
                        props: {
                            loteGlosaId: loteGlosaRecemCriado.id                                   
                        },
                        events: {
                            close: () => this.$router.go(0)
                        },
                        onCancel: () => this.$router.go(0)
                    })
                } catch (err) {
                    this.isLoading = false;
                    if (err.body?.errors) {
                        Object.keys(err.body.errors).forEach((x) => {
                            this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                        });
                    }
                }
            },
            async listarExames() {
                if (this.model.guiaExames == null || this.model.guiaExames.length == 0) {
                    this.isLoading = true;
                    return await this.$http
                        .get(`/api/financeiro/ReceberGuiaExames?receberId=${this.model.id}`)
                        .then((response) => {
                            this.isLoading = false;
                            this.model.guiaExames = response.body;
                            this.model.totalExames = this.totalExames = parseFloat(this.somaDeTodosExames).toFixed(2);
                            this.model.guiaExames?.forEach((g) => {
                                if (g.situacao == 3)
                                    this.valorTotalExamesGlosados += parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(g));
                                else this.valorTotalExamesSemGlosa += parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(g));

                            });
                            this.model.totalExames -= this.valorTotalExamesGlosados;

                            this.totalExames -= this.valorTotalExamesGlosados;                     
                            this.isOpenExames = !this.isOpenExames;
                            this.model.guiaExames.filter((x) => x.situacao === 1).forEach(e => {
                                e.valorPago = this.precoDeduzidoComRecebimentoDoLotePai(e);
                            });
                            this.model.guiaExames.filter((x) => x.situacao === 2 || x.situacao === 4).forEach(e => {
                                this.totalPago += e.valorPago;
                                if(e.situacao == 2 && e.valorPago > 0)
                                    this.totalNaoPago += this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago;
                                
                            });

                            this.model.guiaExames.filter((x) => x.situacao === 2).forEach(e => {
                                this.precoRestante += this.precoDeduzidoComRecebimentoDoLotePai(e) - e.valorPago ;
                            });

                            this.guiaExamesAux = JSON.parse(JSON.stringify(this.model.guiaExames));
                            this.$forceUpdate();
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            if (err.body?.errors) {
                                Object.keys(err.body.errors).forEach((x) => {
                                    this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                });
                            }
                        });
                } else {
                    this.isOpenExames = !this.isOpenExames;
                    this.$forceUpdate();
                }
            },
            removerExame(exame) {
                exame.removido = true;
            },
            incluirGuia() {
                this.$buefy.dialog.prompt({
                    title: "Guia",
                    message: "Confirme a guia que deseja incluir.",
                    inputAttrs: {
                        type: "number",
                        placeholder: "Guia",
                    },
                    confirmText: "Confirmar",
                    cancelText: "Cancelar",
                    trapFocus: true,
                    onConfirm: (res) => {
                        //faz ajax para buscar os exames não faturados e não cancelados dessa guia
                        this.isLoading = true;
                        this.$http
                            .get(`/api/financeiro/ReceberGuiaExamesPendentes?guiaId=${res}`)
                            .then((response) => {
                                this.isLoading = false;
                                const exames = response.body;

                                exames.forEach((exame) => {
                                    this.model.guiaExames.push({
                                        id: 0,
                                        guiaExame: exame,
                                        situacao: 1,
                                        incluido: true,
                                        removido: false,
                                    });
                                });
                            })
                            .catch((err) => {
                                this.isLoading = false;
                                if (err.body?.errors) {
                                    Object.keys(err.body.errors).forEach((x) => {
                                        this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                    });
                                }
                            });
                    },
                });
            },
            removerXml(index) {
                this.arquivosInseridos.splice(index, 1);
            },
            calcularValorImposto(imposto){
                const totalExames = this.model.guiaExames.filter((x) => x.situacao !== 3).reduce((a, b) => parseFloat(a) + parseFloat(this.precoDeduzidoComRecebimentoDoLotePai(b)), 0);
                let totalPagoExames = 0;

                if(this.totalPago != 0){
                    totalPagoExames = this.totalPago;
                }
                else {
                    totalPagoExames = totalExames;
                }
                if(imposto != null && imposto.imposto != null && imposto.imposto.nome != "IR"){
                    this.$set(imposto, 'valor', (totalPagoExames + this.model.totalConsumiveis) * (imposto?.aliquota / 100));
                    return imposto.valor;
                }
            },
            calcularTotalGeralImpostos(){
                let total = 0;
                this.model.impostos.forEach(element => {
                    if(element.imposto != null && element.imposto.nome != "IR" && element.valor != null && element.valor != 0 && element.valor != undefined){
                        total += element.valor;
                    }
                });
                return total > 10 ? total : 0;
            }
        },
    };
</script>

<style scoped>
.tags-do-lote {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
